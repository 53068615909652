import CallIcon from "@mui/icons-material/Call";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import config from "../../src/config";
import { INBOX_DATE_FILTER_OPTIONS } from "modules/Retention/constants/constants";

export const STATISTICS_MAPPER = {
  calls_made: {
    label: "Calls Made",
    value: "calls_made",
    color: "#DE7548",
    icon: (
      <CallIcon
        sx={{
          width: "56px",
          height: "56px",
          padding: "12px",
          borderRadius: "4px",
          color: "rgba(222, 117, 72, 1)",
          bgcolor: "rgba(222, 117, 72, 0.1)",
        }}
      />
    ),
  },
  sent_message: {
    label: "Messages Sent",
    value: "sent_message",
    color: "#39A1EA",
    icon: (
      <ChatBubbleOutlineIcon
        sx={{
          width: "56px",
          height: "56px",
          padding: "12px",
          borderRadius: "4px",
          color: "rgba(57, 161, 234, 1)",
          bgcolor: "rgba(57, 161, 234, 0.1)",
        }}
      />
    ),
  },
  interview_taken: {
    label: "Interviews Taken",
    value: "interview_taken",
    color: "#19A589",
    icon: (
      <PersonSearchIcon
        sx={{
          width: "56px",
          height: "56px",
          padding: "12px",
          borderRadius: "4px",
          color: "rgba(25, 165, 137, 1)",
          bgcolor: "rgba(25, 165, 137, 0.1)",
        }}
      />
    ),
  },
  application_close: {
    label: "Applications Closed",
    value: "application_close",
    color: "#E06767",
    icon: (
      <DocumentScannerIcon
        sx={{
          width: "56px",
          height: "56px",
          padding: "12px",
          borderRadius: "4px",
          color: "rgba(224, 103, 103, 1)",
          bgcolor: "rgba(224, 103, 103, 0.1)",
        }}
      />
    ),
  },
};

export const TASK_PROGRESS_COLORS = [
  "#DE7548",
  "#39A1EA",
  "#19A589",
  "#E06767",
];

export const SECTIONS_MAPPER = {
  NEED_CALL: {
    label: "Need Call",
    value: "NEED_CALL",
    color: "#0D3B66",
  },
  FORM: {
    label: "Form",
    value: "FORM",
    color: "#3C6E71",
  },
  DOCUMENT: {
    label: "Document",
    value: "DOCUMENT",
    color: "#5d2a42",
  },
  INTERVIEW: {
    label: "Interview",
    value: "INTERVIEW",
    color: "#EE964B",
  },
  SCREENING: {
    label: "Screening",
    value: "SCREENING",
    color: "#F95738",
  },
  OUTREACH: {
    label: "Outreach",
    value: "OUTREACH",
    color: "#02b875",
  },
};

export const ACTIONS_MAPPER = {
  calls: {
    label: "Call",
    value: "calls",
    icon: (
      <CallIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
  "close-application": {
    label: "Close Application",
    value: "close-application",
    icon: (
      <CloseIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
  "interview-decision": {
    label: "Interview Decision",
    value: "interview-decision",
    icon: (
      <PersonSearchIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
  "documents-review": {
    label: "Documents Review",
    value: "documents-review",
    icon: (
      <DocumentScannerIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
  "send-message": {
    label: "Send Message",
    value: "send-message",
    icon: (
      <SendIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
  "view-edit-profile": {
    label: "View/Edit Profile",
    value: "view-edit-profile",
    icon: (
      <PersonIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    ),
  },
};

export const ACTIONS = [
  "view-edit-profile",
  "documents-review",
  "interview-decision",
  "send-message",
  "calls",
  "close-application",
];

export const STATUS_MAPPER = {
  outreached: {
    label: "Outreached",
    value: "outreached",
    color: "#023047",
    isUsedForChangeStatus: true,
    description: "Applicant received outreach message",
  },
  "interview-invited": {
    label: "Interview Invited",
    value: "interview-invited",
    color: "#2A9D8F",
    isUsedForChangeStatus: true,
    description: "Applicant invited to the interview",
  },
  "interview-un-answered": {
    label: "Interview Unanswered",
    value: "interview-un-answered",
    color: "#F77F00",
    isUsedForChangeStatus: true,
    description: "Applicant didn't show up during the interview",
  },
  "documents-requested": {
    label: "Documents Requested",
    value: "documents-requested",
    color: "#003049",
    isUsedForChangeStatus: true,
    description: "Applicant requested to send documents",
  },
  "documents-finished": {
    label: "Documents Finished",
    value: "documents-finished",
    color: "#005F73",
    isUsedForChangeStatus: true,
    description: "Applicant submitted all the documents",
  },
  jot_form_request: {
    label: "Jot Form Request",
    value: "jot_form_request",
    color: "#BB3E03",
    isUsedForChangeStatus: true,
    description: "Applicant requested to fill up paperworks",
  },
  "candidate-completed": {
    label: "Candidate Completed",
    value: "candidate-completed",
    color: "#43aa8b",
    isUsedForChangeStatus: true,
    description: "Applicant completed all the hiring stages",
  },
  closed: {
    label: "Closed",
    value: "closed",
    color: "#ff0054",
    isUsedForChangeStatus: true,
    description: "Applicant application is closed",
  },
  "interview-canceled": {
    label: "Interview Canceled",
    value: "interview-canceled",
    color: "#D62828",
    isUsedForChangeStatus: false,
    description:
      "Applicant didn't respond to follow up question to secure interview spot",
  },
  "interview-scheduled": {
    label: "Interview Scheduled",
    value: "interview-scheduled",
    color: "#E76F51",
    isUsedForChangeStatus: false,
    description: "Applicant scheduled the interview",
  },
  jot_form_filled: {
    label: "Jot Form Filled",
    value: "jot_form_filled",
    color: "#003e1f",
    isUsedForChangeStatus: false,
    description: "Applicant completed filling paperworks",
  },
  jot_form_filling: {
    label: "Jot Form Filling",
    value: "jot_form_filling",
    color: "#669bbc",
    isUsedForChangeStatus: false,
  },
  "learn-more": {
    label: "Learn More",
    value: "learn-more",
    color: "#FB8500",
    isUsedForChangeStatus: false,
    description: "Applicant requested for additional information about job",
  },
  "profile-picture-requested": {
    label: "Profile Picture Requested",
    value: "profile-picture-requested",
    color: "#CA6702",
    isUsedForChangeStatus: false,
    description: "Applicant requested to send profile picture",
  },
  reapplied: {
    label: "Reapplied",
    value: "reapplied",
    color: "#219EBC",
    isUsedForChangeStatus: false,
    description: "Applicant reapplied for the same job",
  },
  "screening-agency-questions": {
    label: "Screening Agency Questions",
    value: "screening-agency-questions",
    color: "#264653",
    isUsedForChangeStatus: false,
    description: "Applicant received screening questions",
  },
  matched: {
    label: "Matched",
    value: "matched",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant matched to a the job",
  },
  "not-interested": {
    label: "Not Interested",
    value: "not-interested",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant rejected the job offer",
  },
  "screening-declined": {
    label: "Screening Declined",
    value: "screening-declined",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant rejected in due to screening question response",
  },
  "additional-screening": {
    label: "Additional Screening",
    value: "additional-screening",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant received additional screening form",
  },
  "additional-screening-completed": {
    label: "Additional Screening Completed",
    value: "additional-screening-completed",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant completed additional screening form",
  },
  "additional-screening-declined": {
    label: "Additional Screening Declined",
    value: "additional-screening-declined",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant rejected in additional screening",
  },
  "interview-declined": {
    label: "Interview Declined",
    value: "interview-declined",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant rejected during the interview",
  },
  "candidate-shared": {
    label: "Candidate Shared",
    value: "candidate-shared",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant shared to the Agency",
  },
  "candidate-not-hired": {
    label: "Candidate Not Hired",
    value: "candidate-not-hired",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant not hired by the Agency",
  },
  "candidate-welcomed": {
    label: "Candidate Welcomed",
    value: "candidate-welcomed",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant hired by the Agency",
  },
  documents_change_request: {
    label: "Documents Change Request",
    value: "documents_change_request",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant requested to resubmit documents",
  },
  profile_picture_change_request: {
    label: "Profile Picture Change Request",
    value: "profile_picture_change_request",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant requested to resubmit profile picture",
  },
  on_hold: {
    label: "On Hold",
    value: "on_hold",
    color: "",
    isUsedForChangeStatus: false,
    description: "Applicant current on hold",
  },
};

export const ERROR_CODE = {
  CANCELED: "ERR_CANCELED",
};

export const STATES = [
  {
    id: 1,
    value: "PA",
    label: "PA",
  },
  {
    id: 2,
    value: "NY",
    label: "NY",
  },
  {
    id: 3,
    value: "NJ",
    label: "NJ",
  },
  {
    id: 4,
    value: "DE",
    label: "DE",
  },
  {
    id: 5,
    value: "CT",
    label: "CT",
  },
  {
    id: 5,
    value: "IL",
    label: "IL",
  },
];

export const EMPLOYMENT_STATUSES = [
  {
    id: 1,
    label: "Currently Working - Part Time",
    value: "Employed - Part Time",
  },
  {
    id: 2,
    label: "Currently Working - Full Time",
    value: "Employed - Full Time",
  },
  {
    id: 3,
    label: "Currently Unemployed",
    value: "Unemployed",
  },
];

export const AVAILABILITY_DAYS = {
  SUNDAY: {
    id: 1,
    value: "SUNDAY",
    label: "Sunday",
  },
  MONDAY: {
    id: 2,
    value: "MONDAY",
    label: "Monday",
  },
  TUESDAY: {
    id: 3,
    value: "TUESDAY",
    label: "Tuesday",
  },
  WEDNESDAY: {
    id: 4,
    value: "WEDNESDAY",
    label: "Wednesday",
  },
  THURSDAY: {
    id: 5,
    value: "THURSDAY",
    label: "Thursday",
  },
  FRIDAY: {
    id: 6,
    value: "FRIDAY",
    label: "Friday",
  },
  SATURDAY: {
    id: 7,
    value: "SATURDAY",
    label: "Saturday",
  },
  "(Closed out)": {
    id: 8,
    value: "(Closed out)",
    label: "(Closed Out)",
  },
};

export const AVAILABILITY_TIMES = {
  MORNING: {
    id: 1,
    value: "MORNING",
    label: "Morning",
  },
  AFTERNOON: {
    id: 2,
    value: "AFTERNOON",
    label: "Afternoon",
  },
  EVENING: {
    id: 3,
    value: "EVENING",
    label: "Evening",
  },
  OVERNIGHT: {
    id: 4,
    value: "OVERNIGHT",
    label: "Overnight",
  },
  LIVE_IN: {
    id: 5,
    value: "LIVE_IN",
    label: "Live-In",
  },
  "(Closed out)": {
    id: 7,
    value: "(Closed out)",
    label: "(Closed Out)",
  },
};

export const EXPERIENCES_WILLINGNESS = {
  Dementia: {
    id: 1,
    value: "Dementia",
    label: "Dementia",
  },
  Wheelchair: {
    id: 2,
    value: "Wheelchair",
    label: "Wheelchair",
  },
  Male: {
    id: 3,
    value: "Male",
    label: "Male",
  },
  Female: {
    id: 4,
    value: "Female",
    label: "Female",
  },
  Dog: {
    id: 5,
    value: "Dog",
    label: "Dog",
  },
  Cat: {
    id: 6,
    value: "Cat",
    label: "Cat",
  },
  "All pets": {
    id: 7,
    value: "All pets",
    label: "All Pets",
  },

  "(Closed out)": {
    id: 9,
    value: "(Closed out)",
    label: "(Closed Out)",
  },
};

export const CERTIFICATIONS = {
  CNA: {
    id: 1,
    value: "CNA",
    label: "CNA",
  },
  "Drivers License": {
    id: 4,
    value: "Drivers License",
    label: "Drivers License",
  },
  HHA: {
    id: 2,
    value: "HHA",
    label: "HHA",
  },
  PCA: {
    id: 3,
    value: "PCA",
    label: "PCA",
  },
  "Car insurance": {
    id: 5,
    value: "Car insurance",
    label: "Car Insurance",
  },
  "TB Test": {
    id: 6,
    value: "TB Test",
    label: "TB Test",
  },
  "HS Diploma": {
    id: 9,
    value: "HS Diploma",
    label: "High School Diploma",
  },
  "(Closed out)": {
    id: 13,
    value: "(Closed out)",
    label: "(Closed Out)",
  },
};

export const STATUSES = {
  "documents-requested": {
    id: 1,
    label: "Accept",
    value: "documents-requested",
  },
  "interview-declined": {
    id: 2,
    label: "Decline",
    value: "interview-declined",
  },
  on_hold: {
    id: 3,
    label: "On Hold",
    value: "on_hold",
  },
};

export const FILE_EXTENSIONS = {
  PDF: "pdf",
  XLSX: "xlsx",
  XLS: "xls",
  DOC: "doc",
  DOCX: "docx",
  PPTX: "pptx",
  PPT: "ppt",
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
  SVG: "svg",
  GIF: "gif",
};

export const ENVIRONMENT_TYPES = {
  STAGING: "staging",
  PRODUCTION: "production",
};

export const RECOGNITION_POST_PERMISSIONS = {
  "Agape Care Group": true,
  "Comfort Keepers": true,
  "Helping Hands Family": true,
  "HealthPRO Heritage": true,
  "Royal Home Care": true,
  "Sample Levo Company": true,
  "Graham Healthcare Group": true,
};

export const REFERRAL_SMS_PERMISSIONS = {
  "Comfort Keepers": true,
  "Royal Home Care": true,
  "Sample Levo Company": true,
};

export const ANALYTICS_DASHBOARD = {
  "Agape Care Group": {
    enabled: true,
    configs: [
      {
        id: config.analyticsEmbedId,
        token: config.analyticsEmbedToken,
      },
    ],
  },
  "Graham Healthcare Group": {
    enabled: true,
    configs: [
      {
        id: config.analyticsEmbedId,
        token: config.analyticsEmbedToken,
      },
    ],
  },
  "Helping Hands Family": {
    enabled: true,
    configs: [
      {
        id: config.analyticsEmbedId,
        token: config.analyticsEmbedToken,
      },
      {
        id: config.analyticsHHFEmbedId,
        token: config.analyticsHHFEmbedToken,
      },
    ],
  },
  "Sample Levo Company": {
    enabled: true,
    configs: [
      {
        id: config.analyticsEmbedId,
        token: config.analyticsEmbedToken,
      },
      {
        id: config.analyticsHHFEmbedId,
        token: config.analyticsHHFEmbedToken, // for testing purpose
      },
    ],
  },
};

export const EMPLOYEE_ROLES = {
  admin: {
    label: "Admin",
    value: "admin",
  },
  caregiver: {
    label: "User",
    value: "caregiver",
  },
};

export const AGENCY_ROLE = {
  agency: {
    label: "Agency",
    value: "agency",
  },
};

export const DEFAULT_INBOX_DATE_FILTER = {
  "Graham Healthcare Group": INBOX_DATE_FILTER_OPTIONS["Last 90 days"],
};

export const REPORTS_DASHBOARD = {
  "Caregiver IDD": {
    showOnlyEmbedReports: false,
    showAgencySpecificEmbeddedReports : true,
    showLevoEmbeddedReports: true,
    showOtherReports: false,
    embedIds: config.embedDashboardIdsCaregiver
  },
  "Agape Care Group": {
    showOnlyEmbedReports: true,
    showAgencySpecificEmbeddedReports : false,
    showLevoEmbeddedReports: false,
    showOtherReports: false,
    embedIds: config.embedDashboardIdsAgape
  },
}
