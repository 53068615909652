import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "services/auth";
import { mixpanelLoginEvent } from "services/mixpanel";
import { TextField } from "subframe";
import { Button } from "subframe/components/Button";
import { LinkButton } from "subframe/components/LinkButton";
import { get, set } from "utils/localStorage";
import { isEmpty as isStringEmpty } from "utils/string";
import Logo from "../../../../assets/images/logoV2.svg";
import { fetchUser } from "../../../../services/applications";
import { EVENT_TYPES } from "constants/mixpanel";
import { updateFirebaseSignOutStatus }  from "../../../../services/auth";

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasTriedLoggingIn, setHasTriedLoggingIn] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const from = location.state?.from;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginAsync = async (email, password) => {
    try {
      setIsLoggingIn(true);
      const response = await login(email, password);
      const token = response?.data?.result?.token;
      const user = response?.data?.result?.user;
      const refreshToken = response?.data?.result?.["refresh-token"];

      if (!isStringEmpty(token)) {
        set("token", token);
        set("refreshToken", refreshToken);
        set("userRole", user);
        set("email", email);
        get("token");

        await updateFirebaseSignOutStatus(email);

        const userDetails = await fetchUser(user);
        const agency_name = userDetails?.result?.name
        const agencyId = userDetails?.result?.agency_id

        set("agency_name", agency_name);
        set("full_name", agency_name);
        set("agency_id", agencyId)
        mixpanelLoginEvent(EVENT_TYPES.AGENCY_LOGIN); // Emit login analytics event

        navigate(isStringEmpty(from) ? "/retention/V2" : from);
      }
    } catch (error) {
      setErrorMessage(
        `Error while Logging In: ${error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleLoginClick = () => {
    setHasTriedLoggingIn(true);

    if (isStringEmpty(email) || isStringEmpty(password)) {
      return;
    }

    handleLoginAsync(email, password);
  };

  return (
    <>
      <div className="w-full h-full items-start flex bg-default-background">
        <div className="hidden sm:flex grow shrink-0 basis-0 w-full h-full gap-12 pt-12 pr-12 pb-12 pl-12 items-center justify-center bg-brand-50">
          <div className="flex-col gap-6 items-start flex">
            <span className="text-default-font text-header font-header">
              Understand how your workers are with Levo.
            </span>
            <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-200" />
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Easy set up, no coding required.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                SMS communications, no downloads needed.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Unlimited custom questions and timing.
              </span>
            </div>
          </div>
        </div>
        <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-6 pt-12 pr-12 pb-12 pl-12 items-start flex">
          <img className="flex-none h-12 w-20" src={Logo} alt="levo-logo" />
          <div className="grow shrink-0 basis-0 h-full w-full flex-col gap-12 items-start justify-center flex">
            <div className="gap-2 items-center flex">
              <span className="text-default-font text-header font-header">
                Agency Login
              </span>
            </div>
            <div className="w-full flex-col gap-4 items-start justify-center flex">
              <TextField
                value={email}
                className="h-auto w-full flex-none"
                label="Email"
                onChange={handleEmailChange}
                error={hasTriedLoggingIn && isStringEmpty(email)}
                helpText={
                  hasTriedLoggingIn && isStringEmpty(email)
                    ? "Email is required."
                    : ""
                }
              >
                <TextField.Input placeholder="" />
              </TextField>

              <TextField
                value={password}
                className="h-auto w-full flex-none"
                label="Password"
                onChange={handlePasswordChange}
                helpText={
                  hasTriedLoggingIn && isStringEmpty(password)
                    ? "Password is required."
                    : ""
                }
                error={hasTriedLoggingIn && isStringEmpty(password)}
              >
                <TextField.Input type="password" placeholder="" />
              </TextField>
            </div>
            <Button
              disabled={isLoggingIn}
              variant="Brand Primary"
              size="Default"
              onClick={handleLoginClick}
              loading={isLoggingIn}
            >
              Login
            </Button>
            <LinkButton
              variant="brand"
              onClick={() => navigate("/employee-login", { state: { from } })}
            >
              Login as employee
            </LinkButton>
          </div>
        </div>
      </div>
      <Snackbar
        open={isErrorMessageShown}
        autoHideDuration={6000}
        onClose={() => setIsErrorMessageShown(false)}
      >
        <MuiAlert
          onClose={() => setIsErrorMessageShown(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default LoginPage;
