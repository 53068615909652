import { get } from "lodash";
import { Tooltip } from "@mui/material";

import { Icon } from "@subframe/core";

import RosterGridView from "../RosterGridView";

import { getRelativeDate } from "utils/date";
import { isEmpty as isArrayEmpty } from "utils/array";
import { getFullName, isEmpty as isStringEmpty } from "utils/string";

import "./RosterGrid.css";

import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";
import { useLargeMobileLayout } from "hooks/mediaQueryHooks";

export default function RosterGrid({
  rowCount,
  rows = [],
  paginationModel,
  isLoading = false,
  setRosterPaginationModel,
  viewEmployeeProfile = () => {},
  sortModel = [],
  onSortModelChange = () => {},
  filterModel = {},
  onFilterModelChange = () => {},
  availableLocations = [],
  jobsData = [],
  tenures = [],
}) {
  const lgMobileLayout = useLargeMobileLayout();
  const getFilterOptions = (key) => {
    if (key === "office_location" && !isArrayEmpty(availableLocations)) {
      return availableLocations;
    }

    if (key === "job_title" && !isArrayEmpty(jobsData)) {
      return jobsData.map((job) => job.job_title);
    }

    if (key === "tenure" && !isArrayEmpty(tenures)) {
      return tenures;
    }

    if (isArrayEmpty(rows)) {
      return [];
    }

    const rawOptions = rows.map((row) => row?.profile?.[key] ?? "");
    const unFilteredUniqueOptions = [...new Set(rawOptions)];
    const filteredUniqueOptions = unFilteredUniqueOptions?.filter(
      (option) => !isStringEmpty(option)
    );

    return filteredUniqueOptions;
  };

  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      sortable: true,
      filterable: true,
      type: "string",
      renderCell: (params) => (
        <>
          <div
            className={`custom-datagrid-cell-wrapper employee-cell ${
              !params?.row?.profile?.is_active ? "muted" : ""
            }`}
          >
            {params?.value}
          </div>
          {!params?.row?.profile?.is_active && (
            <div className="terminated-employee-icon-cell">
              <Tooltip id="button-report" title="Terminated">
                <Icon name="FeatherUserX" className="text-error-700" />
              </Tooltip>
            </div>
          )}
        </>
      ),
    },
    {
      field: "profile.job_title",
      headerName: "Job",
      align: "left",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      sortable: true,
      filterable: true,
      type: "singleSelect",
      valueOptions: getFilterOptions("job_title"),
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "profile.office_location",
      headerName: "Location",
      align: "left",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      sortable: true,
      filterable: true,
      type: "singleSelect",
      valueOptions: getFilterOptions("office_location"),
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "profile.tenure",
      headerName: "Tenure",
      flex: 1,
      minWidth: 140,
      align: "left",
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: true,
      type: "singleSelect",
      valueOptions: getFilterOptions("tenure"),
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "survey.['Sentiment Score']",
      headerName: "Overall Sentiment",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {renderSentimentBadgeWithScore(
            params?.value,
            params?.row?.survey?.["Overall Sentiment"],
            params?.row?.survey?.["Response Rate"],
            true
          )}
        </div>
      ),
    },
    {
      field: "latest_survey.['trigger_day']",
      headerName: "Day",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {params?.row?.latest_survey?.trigger_day}
        </div>
      ),
    },
    {
      field: "latest_survey.['Sentiment Score']",
      headerName: "Sentiment",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          }`}
        >
          {renderSentimentBadgeWithScore(
            params?.value,
            params?.row?.latest_survey?.["Overall Sentiment"],
            params?.row?.latest_survey?.["Response Rate"],
            true
          )}
        </div>
      ),
      sortable: true,
      filterable: false,
    },
    {
      field: "latest_survey.['created_at']",
      headerName: "Date",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          className={`custom-datagrid-cell-wrapper ${
            !params?.row?.profile?.is_active ? "muted" : ""
          } first-letter:uppercase`}
        >
          {getRelativeDate(params?.row?.latest_survey?.created_at)}
        </div>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Latest Survey",
      children: [
        { field: "latest_survey.['trigger_day']" },
        { field: "latest_survey.['Sentiment Score']" },
        { field: "latest_survey.['created_at']" },
      ],
    },
  ];

  /**
   * Maps and gets the data for the DataGrid component.
   *
   * @param {String} key
   * @param {Object} row
   * @returns {String | Number}
   */
  const dataMapper = (key, row) => {
    switch (key) {
      case "employee":
        return getFullName(row?.profile?.first_name, row?.profile?.last_name);
      default:
        return get(row, key);
    }
  };

  /**
   * Arranges the data for the Datagrid component.
   *
   * @param {Array} rows
   * @param {Array} columns
   * @returns {Array}
   */
  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => {
      let arrangedRow = {
        id: row?.profile?.employee_id,
        ...row,
      };

      columns.forEach((column) => {
        arrangedRow = {
          ...arrangedRow,
          [column["field"]]: dataMapper(column["field"], row),
        };
      });

      return arrangedRow;
    });
  };
  return (
    <RosterGridView
      columns={columns}
      rowCount={rowCount}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      paginationModel={paginationModel}
      viewEmployeeProfile={viewEmployeeProfile}
      setRosterPaginationModel={setRosterPaginationModel}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      {...(!lgMobileLayout && { columnGroupingModel })}
    />
  );
}
