import {
  Autocomplete,
  Box,
  Chip,
  Snackbar,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";

import * as SubframeCore from "@subframe/core";
import { CustomCheckbox, IconButton, TextArea, ToggleGroup } from "subframe";
import { Button } from "subframe/components/Button";

import { isEmpty as isObjectEmpty } from "utils/object";
import { isEmpty as isStringEmpty } from "utils/string";
import { isEmpty as isArrayEmpty } from "utils/array";

import { EVENT_TYPES } from "constants/mixpanel";
import { mixpanelTrackEvent } from "services/mixpanel";

import {
  ESCALATION_FOLLOW_UP_ENABLED,
  ESCALATION_FOLLOW_UP_NOTE_TAGS,
  ESCALATION_FOLLOW_UP_OUTCOME,
  ESCALATION_FOLLOW_UP_TAGS,
} from "modules/Retention/constants/constants";
import {
  fetchCaregiverRoles,
  followUpEscalation,
} from "modules/Retention/services/retention";

function EscalationFollowUp({
  flat = false,
  focused = false,
  escalationId,
  canMinimize = false,
  isMinimized = false,
  onSubmit = () => {},
  onMinimize = () => {},
  isLogNotes = false,
  employee = {},
  user = {},
}) {
  const [notes, setNotes] = useState("");
  const [outcome, setOutcome] = useState(
    ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetchingCaregiverRoles, setIsFetchingCaregiverRoles] =
    useState("false");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [shouldForwardFollowUp, setShouldForwardFollowUp] = useState(false);
  const [hasTriedFormSubmission, setHasTriedFormSubmission] = useState(false);
  const [eligibleEscalationRecipients, setEligibleEscalationRecipients] =
    useState([]);
  const [
    selectedEligibleEscalationRecipient,
    setSelectedEligibleEscalationRecipient,
  ] = useState({});
  const [selectedStatusTags, setSelectedStatusTags] = useState([]);
  const [selectedNoteTags, setSelectedNoteTags] = useState([]);

  useEffect(() => {
    setSelectedEligibleEscalationRecipient({
      caregiver_name: employee?.profile?.supervisor,
      caregiver_email: employee?.profile?.supervisor_email,
    });
  }, [employee]);

  useEffect(() => {
    handleFetchCaregiverRoles();
  }, []);

  // check for status state validation
  const statusFieldIsValid =
    !isStringEmpty(outcome) &&
    outcome === ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value
      ? !isArrayEmpty(selectedStatusTags)
      : true;

  const isForwardFollowUpFeatureEnabled =
    !!ESCALATION_FOLLOW_UP_ENABLED[user?.agency || user?.name];

  const isFormValid =
    !isStringEmpty(outcome) &&
    !isStringEmpty(notes) &&
    (!isForwardFollowUpFeatureEnabled
      ? true
      : !shouldForwardFollowUp ||
        (!isObjectEmpty(selectedEligibleEscalationRecipient) &&
          !isStringEmpty(
            selectedEligibleEscalationRecipient.caregiver_email
          ))) &&
    statusFieldIsValid;

  const resetForm = () => {
    setOutcome(ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value);
    setNotes("");
    setHasTriedFormSubmission(false);
    setShouldForwardFollowUp(false);
    setSelectedNoteTags([]);
  };

  /**
   * Submits the escalation follow-up form.
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  const submitEscalationFollowUpAsync = async (payload) => {
    try {
      return await followUpEscalation(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const fetchCaregiverRolesAsync = async () => {
    try {
      return await fetchCaregiverRoles();
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const handleFetchCaregiverRoles = async () => {
    setIsFetchingCaregiverRoles(true);
    const response = await fetchCaregiverRolesAsync();

    setIsFetchingCaregiverRoles(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching eligible escalation recipients: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setEligibleEscalationRecipients([]);

      return;
    }

    setEligibleEscalationRecipients(response?.result?.caregivers);
  };

  /**
   * Handles the submission of the escalation follow-up.
   */
  const handleFormSubmit = async () => {
    setHasTriedFormSubmission(true);

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);
    const response = await submitEscalationFollowUpAsync({
      ...(!isLogNotes && { escalation_id: +escalationId }),
      employee_id: employee?.profile?.employee_id,
      outcome,
      notes,
      should_forward: isForwardFollowUpFeatureEnabled && shouldForwardFollowUp,
      ...(isForwardFollowUpFeatureEnabled &&
        shouldForwardFollowUp && {
          forward_email: selectedEligibleEscalationRecipient?.caregiver_email,
        }),
      noter_email: user?.email,
      ...(!isLogNotes &&
        outcome === ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value && {
          tags: (selectedStatusTags ?? []).map(
            (selectedStatusTag) => selectedStatusTag.value
          ),
        }),
      note_tags: (selectedNoteTags ?? []).map(
        (selectedNoteTag) => selectedNoteTag.value
      ),
    });

    setIsSubmitting(false);

    mixpanelTrackEvent(EVENT_TYPES.LOG_FOLLOW_UP_SEND);

    if (response?.hasError) {
      setErrorMessage(`Error while submitting: ${response?.error?.message}`);
      setIsErrorMessageShown(true);

      return;
    }

    resetForm();
    setSuccessMessage("Successfully logged follow-up.");
    setIsSuccessMessageShown(true);
    onSubmit();
    onMinimize();
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Nunito",
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: 400,
        color: "#171717",
      },
    },
  });

  return (
    <>
      {!isMinimized && (
        <div
          className={SubframeCore.twClassNames(
            "w-full border-2 border-solid border-neutral-border rounded flex-col shadow items-start flex bg-default-background group/a7126f05 sticky bottom-0",
            {
              "border-2 border-solid border-default-background shadow-none":
                flat,
              "border-2 border-solid border-brand-primary": focused,
            }
          )}
        >
          <div className="w-full gap-2 pt-3 pr-3 pl-5 items-center flex">
            <div className="grow shrink-0 basis-0 w-full gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-600 text-body font-body"
                name="FeatherMessageSquarePlus"
              />
              <span className="text-default-font text-body-bold font-body-bold">
                {isLogNotes ? "Log Notes" : "Log Follow-up"}
              </span>
            </div>
            {canMinimize && (
              <IconButton
                icon="FeatherMinimize2"
                size="small"
                title="Minimize"
                onClick={onMinimize}
              />
            )}
          </div>
          <div className="w-full flex-col gap-2 pt-4 pr-4 pb-4 pl-4 items-start flex">
            {!isLogNotes && (
              <ToggleGroup
                className="flex-none h-auto w-full"
                value={outcome}
                onValueChange={setOutcome}
              >
                <ToggleGroup.Item
                  className="grow shrink-0 basis-0 w-full h-10 justify-center"
                  icon="FeatherHourglass"
                  value={ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value}
                >
                  {ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.label}
                </ToggleGroup.Item>
                <ToggleGroup.Item
                  className="grow shrink-0 basis-0 w-full h-10 justify-center"
                  icon="FeatherCheck"
                  value={ESCALATION_FOLLOW_UP_OUTCOME.SUCCESSFUL.value}
                >
                  {ESCALATION_FOLLOW_UP_OUTCOME.SUCCESSFUL.label}
                </ToggleGroup.Item>
                <ToggleGroup.Item
                  className="grow shrink-0 basis-0 w-full h-10 justify-center"
                  icon="FeatherX"
                  value={ESCALATION_FOLLOW_UP_OUTCOME.DISMISSED.value}
                >
                  {ESCALATION_FOLLOW_UP_OUTCOME.DISMISSED.label}
                </ToggleGroup.Item>
              </ToggleGroup>
            )}
            <TextArea
              onChange={(event) => {
                setNotes(event?.target?.value);
              }}
              className="flex-none h-auto w-full"
              label=""
              helpText={
                hasTriedFormSubmission && isStringEmpty(notes)
                  ? "Please write in a note"
                  : ""
              }
              error={hasTriedFormSubmission && isStringEmpty(notes)}
            >
              <TextArea.Input
                value={notes}
                placeholder={
                  isLogNotes ? "Add notes..." : "Add follow-up notes..."
                }
              />
            </TextArea>
            {outcome === ESCALATION_FOLLOW_UP_OUTCOME.IN_PROGRESS.value && (
              <div className="w-full mt-1">
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    multiple
                    size="small"
                    disablePortal
                    id="status-tags"
                    value={selectedStatusTags}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={option.label}
                          {...getTagProps({ index })}
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Nunito",
                            color: option.color.text,
                            backgroundColor: option.color.fill,
                          }}
                        />
                      ))
                    }
                    options={Object.values(ESCALATION_FOLLOW_UP_TAGS).map(
                      (tag) => ({
                        label: tag.label,
                        value: tag.value,
                        color: tag.color,
                      })
                    )}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Status"
                        error={hasTriedFormSubmission && !statusFieldIsValid}
                        helperText={
                          hasTriedFormSubmission && !statusFieldIsValid
                            ? "Please choose one status"
                            : ""
                        }
                      />
                    )}
                    renderOption={(params, option) => {
                      return (
                        <Box
                          key={option.value}
                          {...params}
                          sx={{
                            color: option.color.text,
                          }}
                        >
                          {option.label}
                        </Box>
                      );
                    }}
                    onChange={(_, newValue) => {
                      if (newValue?.length > 1) {
                        newValue = newValue.slice(-1); // Keep only the last selected value
                      }
                      setSelectedStatusTags(newValue);
                    }}
                  />
                </ThemeProvider>
              </div>
            )}
            <div className="w-full mt-1">
              <ThemeProvider theme={theme}>
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  id="note-tags"
                  value={selectedNoteTags}
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        size="small"
                        key={index}
                        label={option.label}
                        {...getTagProps({ index })}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Nunito",
                          color: option.color.text,
                          backgroundColor: option.color.fill,
                        }}
                      />
                    ))
                  }
                  options={Object.values(ESCALATION_FOLLOW_UP_NOTE_TAGS).map(
                    (tag) => ({
                      label: tag.label,
                      value: tag.value,
                      color: tag.color,
                    })
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Tags" />
                  )}
                  renderOption={(params, option) => {
                    return (
                      <Box
                        key={option.value}
                        {...params}
                        sx={{
                          color: option.color.text,
                        }}
                      >
                        {option.label}
                      </Box>
                    );
                  }}
                  onChange={(_, newValue) => {
                    if (newValue?.length > 2) {
                      // Keep only the first and last selected values, removing the middle one.
                      // We restrict to at-most two tags for a note.
                      newValue = [newValue[0], newValue.slice(-1)[0]];
                    }
                    setSelectedNoteTags(newValue);
                  }}
                />
              </ThemeProvider>
            </div>

            {isForwardFollowUpFeatureEnabled && !isLogNotes && (
              <div className="flex w-full flex-col">
                <div className="flex w-full align-center grow shrink-0 basis-0 items-center gap-2 py-2">
                  <CustomCheckbox onCheckedChange={setShouldForwardFollowUp} />
                  <div className="flex w-full items-center gap-1 flex-wrap">
                    <span className="text-label font-label text-default-font">
                      Forward to
                    </span>
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        loading={isFetchingCaregiverRoles}
                        size="small"
                        disablePortal
                        id="forward-to-combo-box"
                        value={selectedEligibleEscalationRecipient}
                        options={eligibleEscalationRecipients}
                        onChange={(_, newValue) => {
                          setSelectedEligibleEscalationRecipient(
                            newValue ?? {}
                          );
                        }}
                        getOptionLabel={(option) =>
                          option?.caregiver_name || ""
                        }
                        sx={{ width: 200, marginTop: "2px" }}
                        renderInput={(params) => (
                          <div className="w-full">
                            <TextField
                              {...params}
                              variant="standard"
                              size="small"
                              sx={{
                                input: {
                                  color: "#171717",
                                },
                              }}
                              error={
                                (hasTriedFormSubmission &&
                                  shouldForwardFollowUp &&
                                  isObjectEmpty(
                                    selectedEligibleEscalationRecipient
                                  )) ||
                                (hasTriedFormSubmission &&
                                  shouldForwardFollowUp &&
                                  !isObjectEmpty(
                                    selectedEligibleEscalationRecipient
                                  ) &&
                                  isStringEmpty(
                                    selectedEligibleEscalationRecipient?.caregiver_email
                                  ))
                              }
                            />
                          </div>
                        )}
                      />
                    </ThemeProvider>
                  </div>
                </div>
                {hasTriedFormSubmission &&
                  shouldForwardFollowUp &&
                  isObjectEmpty(selectedEligibleEscalationRecipient) && (
                    <span class="text-label font-label text-error-700">
                      Please choose a user to forward this to.
                    </span>
                  )}

                {hasTriedFormSubmission &&
                  shouldForwardFollowUp &&
                  !isObjectEmpty(selectedEligibleEscalationRecipient) &&
                  isStringEmpty(
                    selectedEligibleEscalationRecipient?.caregiver_email
                  ) && (
                    <span class="text-label font-label text-error-700">
                      Selected user's email is missing.
                    </span>
                  )}
              </div>
            )}
            <div className="w-full gap-2 justify-end flex">
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}

export default EscalationFollowUp;
