import React, { useEffect, useState } from "react";
import { Modal as MuiModal } from "@mui/material";
import { Button } from "subframe/components/Button";
import { RadioGroup } from "subframe/components/RadioGroup";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { TextField } from "subframe";

import {
  createCaregiverRolePolicy,
  updateCaregiverRolePolicy,
} from "services/retention/employees";
import {
  ACCESSIBILITY_TYPE,
  ACCESSIBILITY_TYPE_ENUM,
  ROLE_TYPE,
  ROLE_TYPE_ENUM,
} from "modules/Retention/constants/constants";
import UserPolicyMultiSelectDropdown from "./UserPolicyMultiSelectDropdown";

export default function UserPolicyModal({
  open,
  onClose,
  data = null,
  setRefetchData = () => {},
  updateData = () => {},
  userPolicyOptions,
  onSuccess,
}) {
  const [accessibilityType, setAccessibilityType] = useState(() =>
    data && data.type !== "-" ? data.type : ACCESSIBILITY_TYPE_ENUM.REGION
  );
  const [email, setEmail] = useState("");
  const [roleType, setRoleType] = useState(ROLE_TYPE_ENUM.CAREGIVER);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);

  useEffect(() => {
    setSelectedValues([]);
  }, [accessibilityType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const accessScope = selectedValues.map((v) => v.label);

      const basePayload = {
        method: "GET",
        type: accessibilityType,
        value: accessScope,
      };

      if (data) {
        await updateCaregiverRolePolicy({
          email: data.email,
          policy: [{ ...basePayload, role: data.role }],
        });
        setSuccessMessage("Caregiver role policy updated successfully");
        setIsSuccessMessageShown(true);
        updateData({ ...data, type: accessibilityType, value: accessScope });
      } else {
        await createCaregiverRolePolicy({
          email: email.trim(),
          role_type: roleType,
          policy: [{ ...basePayload, role: roleType }],
        });
        setRefetchData(true);
        setSuccessMessage("Caregiver role created successfully");
        setIsSuccessMessageShown(true);
      }
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error("Failed to create caregiver role:", error);
      setErrorMessage(
        error?.response?.data?.message || "Failed to create caregiver role"
      );
      setIsErrorMessageShown(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <MuiModal
        open={open}
        onClose={onClose}
        aria-labelledby="user-policy-edit-modal"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 w-[400px]">
          <h2
            id="user-policy-edit-modal"
            className="text-lg font-semibold mb-4"
          >
            {`${data ? "Edit" : "Add"} accessibility policy`}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {!data && (
              <>
                <TextField
                  value={email}
                  className="h-auto w-full flex-none"
                  label="Email"
                  aria-required={true}
                  onChange={(e) => setEmail(e.target.value)}
                >
                  <TextField.Input placeholder="Email" required />
                </TextField>
                <RadioGroup
                  label="Role Type"
                  helpText=""
                  error={false}
                  horizontal={true}
                  value={roleType}
                  onValueChange={(value) => {
                    setRoleType(value);
                  }}
                >
                  {ROLE_TYPE.map((type) => (
                    <RadioGroup.Option label={type.label} value={type.value} />
                  ))}
                </RadioGroup>
              </>
            )}
            <div>
              <RadioGroup
                label="Accessibility Type"
                helpText=""
                error={false}
                horizontal={true}
                value={accessibilityType}
                onValueChange={(value) => {
                  setAccessibilityType(value);
                }}
              >
                {ACCESSIBILITY_TYPE.map((type) => (
                  <RadioGroup.Option
                    label={type.label}
                    value={type.value}
                    key={type.value}
                  />
                ))}
              </RadioGroup>
            </div>
            <div>
              <UserPolicyMultiSelectDropdown
                userPolicyOptions={userPolicyOptions}
                initialData={data}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                accessibilityType={accessibilityType}
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button
                variant="Brand Secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="Brand Primary"
                loading={isSubmitting}
                type="submit"
              >
                {data ? "Update Policy" : "Add Policy"}
              </Button>
            </div>
          </form>
        </div>
      </MuiModal>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}
