import React, { useEffect, useState } from "react";
import * as SubframeCore from "@subframe/core";
import { TextField } from "subframe";
import {
  fetchCaregiverAccessibilityType,
  fetchCaregiverRolesByAgency,
} from "services/retention/employees";
import { get } from "utils/localStorage";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import OrganizationUserGrid from "../OrganizationUsersGrid/OrganizationUserGrid";
import UserPolicyModal from "../UserPolicyModal/UserPolicyModal";
import MultiSelectDropdown from "../MultiSelectDropdown";
import { userPolicySearchableColumns } from "modules/Retention/constants/constants";

const OrganizationSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isAccessibilityTypeLoading, setIsAccessibilityTypeLoading] =
    useState(false);

  const [organizationUserPaginationModel, setOrganizationUserPaginationModel] =
    useState({
      page: 0,
      pageSize: 20,
    });

  const [selectedValues, setSelectedValues] = useState([]);
  const [initialValue, setInitialValue] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [organizationUsersRowCount, setOrganizationUserRowCount] = useState(0);
  const [accessibilityType, setAccessibilityType] = useState({
    region: [],
    office_location: [],
  });

  async function fetchOrganizationUsersWithPolicies(params) {
    // Fetch organization users with policies
    try {
      setIsLoading(true);

      const requestParams = {
        agencyId: get("agency_id"),
        page: params.page + 1,
        limit: params.pageSize,
        sortField: params.sortField ? params.sortField : null,
        sortOrder: params.sortOrder ? params.sortOrder : null,
        filters: params.filters ? params.filters : null,
      };

      const response = await fetchCaregiverRolesByAgency(requestParams);

      if (response?.success) {
        const data = (response?.result?.caregiver_roles || []).filter(
          (role) => role.email !== get("email")
        );

        setOrganizationUsers(data);
        setOrganizationUserRowCount(response?.result?.total || 0);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        `Error while fetching organization users with policies: ${error?.message}`
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchAccessibilityType() {
    try {
      setIsAccessibilityTypeLoading(true);
      const requestParams = {
        agencyId: get("agency_id"),
      };

      const response = await fetchCaregiverAccessibilityType(requestParams);

      if (response?.success) {
        const data = response?.result;

        const region = (data?.region || []).map((item) => ({
          label: item,
          value: item.split(" ").join("_").toLowerCase(),
        }));
        const officeLocation = (data?.location || []).map((item) => ({
          label: item,
          value: item.split(" ").join("_").toLowerCase(),
        }));

        setAccessibilityType({
          region,
          office_location: officeLocation,
        });
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        `Error while fetching organization users with policies: ${error?.message}`
      );
    } finally {
      setIsAccessibilityTypeLoading(false);
    }
  }

  useEffect(() => {
    const params = prepareRequestParams(sortModel, filterModel)
    fetchOrganizationUsersWithPolicies(params);
  }, [organizationUserPaginationModel, refetchData]);

  useEffect(() => {
    setInitialValue(
      userPolicySearchableColumns.filter(
        (item) => item.value === "employeeName"
      )
    );

    fetchAccessibilityType();
  }, []);

  useEffect(() => {
    handleFilterChange(filterModel.items[0]?.value || "")
  }, [selectedValues])

  const prepareRequestParams = (orgSortModel = null, orgFilterModel = null) => {
    if (!orgSortModel && !orgFilterModel) {
      return organizationUserPaginationModel;
    }

    const baseParams = {
      ...organizationUserPaginationModel,
      sortField: orgSortModel[0]?.field,
      sortOrder: orgSortModel[0]?.sort?.toLowerCase(),
    };

    const filters = orgFilterModel.items.map((filter) => ({
      field: filter.columnField,
      operator: filter.operatorValue,
      value: filter.value,
    }));

    return {
      ...baseParams,
      filters: JSON.stringify(filters),
    };
  };

  const handleSortModelChange = (newModel) => {
    if (!newModel) {
      return;
    }

    setSortModel(newModel);
    setIsLoading(true);

    const requestParams = prepareRequestParams(newModel, filterModel);

    try {
      fetchOrganizationUsersWithPolicies(requestParams);
    } catch (error) {
      setErrorMessage(`Error while sorting: ${error.message}`);
      setHasError(true);
      setIsLoading(false);
    }
  };

  const handleFilterChange = (searchQuery) => {
    const filterItems = selectedValues.map((item) => ({
      columnField: item.value,
      operator: "contains",
      value: searchQuery,
    }));

    const model = { items: filterItems };
    setFilterModel(model);
    setIsLoading(true);

    const requestParams = prepareRequestParams(sortModel, model);

    try {
      fetchOrganizationUsersWithPolicies(requestParams);
    } catch (error) {
      setErrorMessage(`Error while filtering: ${error.message}`);
      setHasError(true);
      setIsLoading(false);
    }
  };

  const updateTableRow = (data) => {
    const updatedData = organizationUsers.map((user) => {
      if (data && user.caregiver_id === data.id) {
        return { ...user, policy: [{ type: data.type, value: data.value }] };
      }

      return user;
    });

    setOrganizationUsers(updatedData);
  };

  const clearSearch = () => {
    setSelectedValues(initialValue);
    setFilterModel({ items: [] });
    handleFilterChange("")
  };

  return (
    <div className="p-6 w-full bg-white rounded-lg shadow h-full">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <div className="flex items-center md:w-[50%] w-full">
          <TextField
            aria-required={true}
            icon={"FeatherSearch"}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="md:w-[70%] w-[60%] p-0 text-sm flex items-center bg-muted/50 border-none focus:outline-none focus:ring-1 focus:ring-primary"
            onClick={(e) => e.stopPropagation()}
          >
            <TextField.Input
              placeholder="Search by employee name and email"
              value={
                filterModel.items.length !== 0 ? filterModel.items[0].value : ""
              }
            />
          </TextField>
          <div
            className="w-[32px] h-[32px] rounded-sm border border-gray-200 flex items-center justify-center mr-3 cursor-pointer hover:bg-gray-500 group"
            onClick={clearSearch}
          >
            <SubframeCore.Icon
              name="FeatherX"
              className="text-gray-600 text-sm p-1 text-center group-hover:text-white"
            />
          </div>
          <MultiSelectDropdown
            isSearchable={false}
            selectionLabel={false}
            dropdownLabel="Columns"
            dropdownItems={userPolicySearchableColumns}
            initialValues={initialValue}
            selectedValues={selectedValues}
            classnames={"md:w-[30%] w-[40%]"}
            setSelectedValues={setSelectedValues}
          />
        </div>
        <button
          onClick={() => setOpenAddModal(true)}
          className="px-4 py-2 flex items-center border border-gray-200 text-gray-600 text-sm font-medium rounded hover:border-gray-700 transition-colors w-full sm:w-auto"
        >
          <SubframeCore.Icon
            name="FeatherPlus"
            className="text-gray-600 text-lg mr-2"
          />
          <span>Add User Policy</span>
        </button>
      </div>

      <OrganizationUserGrid
        isLoading={isLoading || isAccessibilityTypeLoading}
        data={organizationUsers}
        updateData={updateTableRow}
        sortModel={sortModel}
        onFilterModelChange={handleFilterChange}
        handleSortModelChange={handleSortModelChange}
        rowCount={organizationUsersRowCount}
        userPolicyOptions={accessibilityType}
        paginationModel={organizationUserPaginationModel}
        setPaginationModel={setOrganizationUserPaginationModel}
      />

      <Snackbar
        open={hasError}
        autoHideDuration={6000}
        onClose={() => setHasError(false)}
      >
        <MuiAlert
          onClose={() => setHasError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>

      {openAddModal && (
        <UserPolicyModal
          onClose={() => setOpenAddModal(false)}
          open={openAddModal}
          setRefetchData={setRefetchData}
          userPolicyOptions={accessibilityType}
        />
      )}
    </div>
  );
};

export default OrganizationSettings;
