import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import MicrosoftProvider from "auth_msft_create_provider";
import { EVENT_TYPES } from "constants/mixpanel";
import { fetchSignInMethodsForEmail, signInWithPopup } from "firebase/auth";
import { SIGN_IN_PROVIDERS } from "modules/Retention/constants/constants";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUpdateEmployeeRole, loginEmployee as login } from "services/auth";
import { mixpanelLoginEvent } from "services/mixpanel";
import { OAuthSocialButton, TextField } from "subframe";
import { Button } from "subframe/components/Button";
import { LinkButton } from "subframe/components/LinkButton";
import { get, remove, set } from "utils/localStorage";
import { isEmpty as isStringEmpty } from "utils/string";
import Logo from "../../../../assets/images/logoV2.svg";
import GoogleProvider from "../../../../auth_google_provider_create";
import { auth } from "../../../../firebase";
import { fetchUser } from "../../../../services/applications";
import { updateFirebaseSignOutStatus }  from "../../../../services/auth";

function EmployeeLogin() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasTriedLoggingIn, setHasTriedLoggingIn] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const from = location.state?.from;

  useEffect(() => {
    const accessToken = get("token");
    const refreshToken = get("refreshToken");
    const userRole = get("userRole");

    if (
      !isStringEmpty(accessToken) ||
      !isStringEmpty(refreshToken) ||
      !isStringEmpty(userRole)
    ) {
      navigate("/retention/V2");
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginAsync = async (email, password) => {
    try {
      setIsLoggingIn(true);
      const response = await login(email, password);
      const token = response?.data?.result?.token;
      const role = response?.data?.result?.role;
      const refreshToken = response?.data?.result?.["refresh-token"];

      if (!isStringEmpty(token)) {
        set("token", token);
        set("refreshToken", refreshToken);
        set("userRole", role);
        set("email", email);
        get("token");

        await updateFirebaseSignOutStatus(email);

        const userDetails = await fetchUser(role);
        const agency_name = userDetails?.result?.agency
        const first_name = userDetails?.result?.first_name
        const last_name = userDetails?.result?.last_name
        const agency_id = userDetails?.result?.agency_id
        const full_name = `${first_name} ${last_name}`

        set("agency_name", agency_name);
        set("full_name", full_name);
        set("agency_id", agency_id);
        mixpanelLoginEvent(EVENT_TYPES.EMPLOYEE_LOGIN); // Emit login analytics event

        navigate(isStringEmpty(from) ? "/retention/V2" : from);
      }
    } catch (error) {
      setErrorMessage(
        `Error while Logging In: ${error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleSignInWithPopup = async (auth, provider) => {
    try {
      return await signInWithPopup(auth, provider);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const handleCheckUpdateRole = async (email) => {
    try {
      return await checkUpdateEmployeeRole(email);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const handleContinueWithProvider = async (provider) => {
    let response;
    let firebaseSignInProvider;

    if (provider === SIGN_IN_PROVIDERS.GOOGLE) {
      firebaseSignInProvider = GoogleProvider;
    }

    if (provider === SIGN_IN_PROVIDERS.MICROSOFT) {
      firebaseSignInProvider = MicrosoftProvider;
    }

    response = await handleSignInWithPopup(auth, firebaseSignInProvider);

    if (response?.hasError) {
      const error = response?.error || {};

      if (error.code !== "auth/account-exists-with-different-credential") {
        setErrorMessage(
          `Error while Login (Firebase Sign In) : ${response?.error?.message}`
        );
        setIsErrorMessageShown(true);

        return;
      }

      const signInMethods = await fetchSignInMethodsForEmail(
        auth,
        error.customData?.email
      );

      if (!signInMethods.includes(GoogleProvider.providerId)) {
        setErrorMessage(
          "It looks like you've already signed up using a different method with this email. Please sign in with your email and password, or you can also use Google login with the same email."
        );
        setIsErrorMessageShown(true);

        return;
      }

      response = await handleSignInWithPopup(
        auth,
        GoogleProvider.setCustomParameters({
          login_hint: error.email,
        })
      );
    }

    const email = response?.user?.email;
    const token = response?.user?.accessToken;
    const refreshToken = response?.user?.stsTokenManager?.refreshToken;

    if (isStringEmpty(email)) {
      setErrorMessage(`Error while Login: Email is empty.`);
      setIsErrorMessageShown(true);

      return;
    }

    set("email", email);
    set("token", token);
    set("refreshToken", refreshToken);

    await updateFirebaseSignOutStatus(email);

    const handleCheckUpdateRoleResponse = await handleCheckUpdateRole(email);

    if (handleCheckUpdateRoleResponse?.hasError) {
      setErrorMessage(
        `Error while Login (Check Update Employee Role) : ${handleCheckUpdateRoleResponse?.error?.message}`
      );
      setIsErrorMessageShown(true);

      remove("token");
      remove("email");
      remove("refreshToken");
      remove("agency_name");
      remove("full_name");

      return;
    }

    const userRole = handleCheckUpdateRoleResponse?.result?.["caregiver-role"];

    set("userRole", userRole);

    const userDetails = await fetchUser(userRole);
    const agency_name = userDetails?.result?.agency
    const first_name = userDetails?.result?.first_name
    const last_name = userDetails?.result?.last_name
    const full_name = `${first_name} ${last_name}`

    set("agency_name", agency_name);
    set("full_name", full_name);
    mixpanelLoginEvent(EVENT_TYPES.EMPLOYEE_LOGIN); // Emit login analytics event

    navigate(isStringEmpty(from) ? "/retention/V2" : from);
  };

  const handleLoginClick = () => {
    setHasTriedLoggingIn(true);

    if (isStringEmpty(email) || isStringEmpty(password)) {
      return;
    }

    handleLoginAsync(email, password);
  };

  return (
    <>
      <div className="w-full h-full items-start flex bg-default-background">
        <div className="hidden sm:flex grow shrink-0 basis-0 w-full h-full gap-12 pt-12 pr-12 pb-12 pl-12 items-center justify-center bg-brand-50">
          <div className="flex-col gap-6 items-start flex">
            <span className="text-default-font text-header font-header">
              Understand how your workers are with Levo.
            </span>
            <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-200" />
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Easy set up, no coding required.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                SMS communications, no downloads needed.
              </span>
            </div>
            <div className="gap-2 items-center flex">
              <SubframeCore.Icon
                className="text-brand-700 text-body font-body"
                name="FeatherCheckSquare"
              />
              <span className="text-default-font text-body font-body">
                Unlimited custom questions and timing.
              </span>
            </div>
          </div>
        </div>
        <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-12 pt-12 pr-12 pb-12 pl-12">
          <div className="flex w-full flex-col items-center justify-center gap-7">
            <img className="flex-none h-12 w-20" src={Logo} alt="levo-logo" />
            <div className="flex w-full flex-col items-center justify-center gap-11">
              <span className="text-header font-header text-default-font">
                Welcome to Levo
              </span>
            </div>
          </div>
          <div className="flex w-full max-w-[576px] flex-col items-center justify-center gap-10">
            <div className="flex w-full items-center justify-center gap-6">
              <div className="flex flex-col items-center justify-center gap-2">
                <OAuthSocialButton
                  className="h-12 w-auto flex-none"
                  logo="https://res.cloudinary.com/subframe/image/upload/v1711417516/shared/z0i3zyjjqkobzuaecgno.svg"
                  onClick={() =>
                    handleContinueWithProvider(SIGN_IN_PROVIDERS.GOOGLE)
                  }
                />
                <span className="text-body-bold font-body-bold text-default-font">
                  Google
                </span>
              </div>
              <div className="flex flex-col items-center justify-center gap-2">
                <OAuthSocialButton
                  className="h-12 w-auto flex-none"
                  logo="https://res.cloudinary.com/subframe/image/upload/v1711417533/shared/eurkakulmidjxs4f28rq.jpg"
                  onClick={() =>
                    handleContinueWithProvider(SIGN_IN_PROVIDERS.MICROSOFT)
                  }
                />
                <span className="text-body-bold font-body-bold text-default-font">
                  Microsoft
                </span>
              </div>
            </div>
            <div className="flex w-full items-center justify-center gap-2">
              <div className="flex h-px w-full grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-200" />
              <span className="text-body-bold font-body-bold text-neutral-400">
                or
              </span>
              <div className="flex h-px w-full grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-200" />
            </div>
            <div className="w-full flex-col gap-4 items-start justify-center flex">
              <TextField
                value={email}
                className="h-auto w-full flex-none"
                label="Email"
                onChange={handleEmailChange}
                error={hasTriedLoggingIn && isStringEmpty(email)}
                helpText={
                  hasTriedLoggingIn && isStringEmpty(email)
                    ? "Email is required."
                    : ""
                }
              >
                <TextField.Input placeholder="" />
              </TextField>
              <TextField
                value={password}
                className="h-auto w-full flex-none"
                label="Password"
                onChange={handlePasswordChange}
                helpText={
                  hasTriedLoggingIn && isStringEmpty(password)
                    ? "Password is required."
                    : ""
                }
                error={hasTriedLoggingIn && isStringEmpty(password)}
              >
                <TextField.Input type="password" placeholder="" />
              </TextField>
            </div>
            <Button
              disabled={isLoggingIn}
              variant="Brand Primary"
              size="Default"
              onClick={handleLoginClick}
              loading={isLoggingIn}
              className="h-10 w-full flex-none"
            >
              Login
            </Button>
            <div className="flex w-full flex-col items-start justify-center">
              <LinkButton
                variant="brand"
                onClick={() => navigate("/agency-login", { state: { from } })}
              >
                Login as agency
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={isErrorMessageShown}
        autoHideDuration={6000}
        onClose={() => setIsErrorMessageShown(false)}
      >
        <MuiAlert
          onClose={() => setIsErrorMessageShown(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EmployeeLogin;
