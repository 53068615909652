import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { doc, onSnapshot } from "firebase/firestore"; 
import { signOut } from "firebase/auth"; 
import { auth, firestore } from "../../../../firebase";
import { get, remove } from "utils/localStorage";
import { EMPLOYEE_ROLES } from "constants/constants";
import { logoutEmployee } from "services/auth";
import { mixpanelLogoutEvent } from "services/mixpanel";

const SignOutListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const email = get("email");
    if (!email) return;

    const signOutRef = doc(firestore, `signOutRequests/${email}`);

    async function handleLogout() {
        const email = get("email");
        const userRole = get("userRole");
    
        if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
          try {
            await logoutEmployee(email);
          } catch (error) {
            console.log(error);
          }
        }
    
        remove("token");
        remove("refreshToken");
        remove("userRole");
        remove("email");
        remove("agency_name");
        remove("full_name");
        navigate("/");
        mixpanelLogoutEvent();
      }

    // Listen for snapshot changes
    const unsubscribe = onSnapshot(signOutRef, (doc) => {
      const data = doc.data();
      if (data && data.signOut === true) {
        signOut(auth)
          .then(() => {
            handleLogout();
          })
          .catch((error) => {
            console.error("Error signing out:", error);
          });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return null;
};

export default SignOutListener;
