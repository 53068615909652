import { doc, updateDoc } from "firebase/firestore"; 

import { EMPLOYEE_ROLES } from "constants/constants";
import config from "../config";
import endpoints from "../constants/endpoints";
import { firestore } from "../firebase";
import { http, levoHttp } from "../utils/http";

let isFetchingAccessToken = false;
let refreshTokenPromiseCache = null;

export const login = (email, password) => {
  const loginPayload = {
    email,
    password,
  };
  const url = `${config.authBaseUrl}${endpoints.login}`;

  return http.post(url, loginPayload);
};

/**
 * Refresh Access Token using the API.
 *
 * @param {string} refreshToken
 *
 * @returns {Promise} A promise to a get request to get a new access token.
 */
export const refreshAccessToken = async (refreshToken) => {
  if (!isFetchingAccessToken) {
    isFetchingAccessToken = true;

    const url = `${config.authBaseUrl}${endpoints.refreshToken}`;

    refreshTokenPromiseCache = http
      .post(url, {
        refresh_token: refreshToken,
      })
      .then((response) => {
        isFetchingAccessToken = false;

        const newAccessToken = response?.data?.result?.["token"];
        const newRefreshToken = response?.data?.result?.["refresh-token"];

        return { newAccessToken, newRefreshToken };
      })
      .catch((error) => {
        isFetchingAccessToken = false;

        throw error;
      });
  }

  return refreshTokenPromiseCache;
};

export const loginEmployee = (email, password) => {
  const url = `${config.authBaseUrl}${endpoints.employeeLogin}`;

  return http.post(url, { email, password });
};

export const signUpEmployee = (
  firstName,
  lastName,
  email,
  password,
  roleType = EMPLOYEE_ROLES.caregiver.value
) => {
  const url = `${config.authBaseUrl}${endpoints.employeeSignUp}`;

  return http.post(url, {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    role_type: roleType,
  });
};

export const logoutEmployee = (email) => {
  const url = `${config.authBaseUrl}${endpoints.employeeLogOut}`;

  return levoHttp.post(url, {
    email,
  });
};

export const checkUpdateEmployeeRole = (email) => {
  const url = `${config.authBaseUrl}${endpoints.checkUpdateEmployeeRole}`;

  return levoHttp.post(url, {
    email,
  });
};

export const updateFirebaseSignOutStatus = async (email, status = false) => {
  if (!email) return;

  const signOutRef = doc(firestore, `signOutRequests/${email}`);

  try {
    await updateDoc(signOutRef, { signOut: status });
  } catch (error) {
    console.error(`Error updating sign out status for ${email}:`, error);
  }
};
