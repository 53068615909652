/*
 * Documentation:
 * Settings page — https://app.subframe.com/library?component=Settings+page_3983ed4a-8495-4470-af14-3897dd72ff76
 * Text Input — https://app.subframe.com/library?component=Text+Input_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Switch — https://app.subframe.com/library?component=Switch_7a464794-9ea9-4040-b1de-5bfb2ce599d9
 * InfoIconWithImage — https://app.subframe.com/library?component=InfoIconWithImage_9ba45521-c87f-46f9-b7d1-a2ba7b572c3f
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 */

import React, { useState } from "react";

import { Tab, Tabs } from "@mui/material";

import * as SubframeCore from "@subframe/core";
import { PageHeader } from "subframe";

import { AGENCY_ROLE, EMPLOYEE_ROLES } from "constants/constants";
import { SETTINGS_TAB } from "modules/Retention/constants/constants";
import { get as getUserRole } from "utils/localStorage";
import OrganizationSettings from "./OrganizationSettings";
import UserSettings from "./UserSettings";

function SettingsPage() {
  const [settingsTab, setSettingsTab] = useState(SETTINGS_TAB.USER_SETTINGS);

  const isAdmin = () => getUserRole("userRole") === EMPLOYEE_ROLES.admin.value;
  const isAgency = () => getUserRole("userRole") === AGENCY_ROLE.agency.value;

  return (
    <>
      <div className="grow shrink-0 basis-0 w-full flex-col gap-6 p-4 md:p-12 items-center flex bg-default-background container max-w-none">
        <div className="w-full md:w-4xl flex-col gap-9 items-start flex">
          <div className="w-full flex-col gap-3 items-start flex">
            <PageHeader>
              <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                <SubframeCore.Icon
                  className="text-neutral-400 text-header font-header"
                  name="FeatherSettings"
                />
                <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
                  Settings
                </span>
              </div>
            </PageHeader>

            <span className="w-full text-subtext-color text-body font-body">
              Update your information here
            </span>
          </div>
          {!isAdmin() && (
            <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
          )}

          {/* Tabs */}
          {(isAdmin() || isAgency()) && (
            <Tabs
              sx={{
                minHeight: "8px",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "#d4d4d4",
              }}
              variant="fullWidth"
              value={settingsTab}
              aria-label="recognitions tabs"
              onChange={(event, value) => setSettingsTab(value)}
            >
              <Tab
                sx={{
                  minHeight: "0px",
                  padding: "10px 16px",
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textTransform: "capitalize",
                }}
                value={SETTINGS_TAB.USER_SETTINGS}
                label={`User Settings`}
              />

              <Tab
                sx={{
                  minHeight: "0px",
                  padding: "10px 16px",
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textTransform: "capitalize",
                }}
                value={SETTINGS_TAB.ORGANIZATION_SETTINGS}
                label={`Organization Settings`}
              />
            </Tabs>
          )}

          {settingsTab === SETTINGS_TAB.USER_SETTINGS && <UserSettings />}

          {settingsTab === SETTINGS_TAB.ORGANIZATION_SETTINGS && (
            <OrganizationSettings />
          )}
        </div>
      </div>
    </>
  );
}

export default SettingsPage;
