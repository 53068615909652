"use client";
/*
 * Documentation:
 * Sentiment Badge — https://app.subframe.com/library?component=Sentiment+Badge_e1962f43-fe6e-498c-9b8e-21e501824ce3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface SentimentBadgeRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "no-data" | "neutral" | "low" | "high";
  score?: string;
  variant3?: "default";
  className?: string;
}

const SentimentBadgeRoot = React.forwardRef<
  HTMLElement,
  SentimentBadgeRootProps
>(function SentimentBadgeRoot(
  {
    variant = "no-data",
    score,
    variant3 = "default",
    className,
    ...otherProps
  }: SentimentBadgeRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/e1962f43 flex h-6 items-center gap-1 rounded-full bg-neutral-100 p-4 md:pr-2 md:pl-2",
        {
          "bg-success-100": variant === "high",
          "bg-error-100": variant === "low",
          "bg-warning-100": variant === "neutral",
        },
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "hidden text-body font-body text-success-700",
          { "inline-flex": variant === "high", hidden: variant === "neutral" }
        )}
        name="FeatherSmile"
      />
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "hidden text-body font-body text-warning-700",
          {
            "text-success-700": variant === "high",
            "inline-flex": variant === "neutral",
          }
        )}
        name="FeatherMeh"
      />
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "hidden text-body font-body text-error-700",
          {
            "inline-flex": variant === "low",
            "hidden text-warning-700": variant === "neutral",
          }
        )}
        name="FeatherFrown"
      />
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          "text-body font-body text-default-font",
          {
            hidden:
              variant === "high" || variant === "low" || variant === "neutral",
          }
        )}
        name={null}
      />
      {score ? (
        <span
          className={SubframeCore.twClassNames(
            "text-label font-label text-default-font",
            {
              "text-success-800": variant === "high",
              "text-error-700": variant === "low",
              "text-warning-700": variant === "neutral",
              "mx-auto" : variant === "no-data"
            }
          )}
        >
          {variant === "no-data" ? "-" : score}
        </span>
      ) : null}
    </div>
  );
});

export const SentimentBadge = SentimentBadgeRoot;
