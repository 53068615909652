import classNames from "classnames";
import React from "react";
import * as SubframeCore from "@subframe/core";
import { isEmpty } from "utils/array";

export default function MultiSelectDropdown({
  selectedValues,
  setSelectedValues,
  initialValues = [],
  dropdownLabel = "",
  dropdownItems = [],
  isSearchable = true,
  classnames = "",
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  React.useEffect(() => {
    if (!isEmpty(initialValues)) {
      setSelectedValues(initialValues);
    }
  }, [initialValues, setSelectedValues]);

  const toggleItem = (value) => {
    setSelectedValues((current) => {
      const isValueSelected = current.find((item) => item.value === value);

      if (isValueSelected) {
        return selectedValues.filter((item) => item.value !== value);
      }

      const newItem = dropdownItems.find((item) => item.value === value);
      const itemToAdd = newItem
        ? { label: newItem.label, value: newItem.value }
        : { label: value, value };

      return [...current, itemToAdd];
    });
  };

  const filteredDropdownItems = dropdownItems.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const dropdownClassNames = classNames("flex flex-col gap-4 w-full max-w-md", {
    [classnames]: !!classnames,
  });

  return (
    <div className={dropdownClassNames}>
      <div className="relative" ref={dropdownRef}>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={classNames(
            "flex items-center justify-between w-full px-4 py-2.5 text-sm border rounded-lg cursor-pointer transition-all duration-200",
            isOpen
              ? "border-primary ring-2 ring-primary/10"
              : "border-border hover:border-primary/50"
          )}
        >
          <div className="flex-1 flex items-center gap-1.5">
            {selectedValues.length > 0 ? (
              <span className="text-sm font-medium">
                {selectedValues.length} selected
              </span>
            ) : (
              <span className="text-muted-foreground">
                Select {dropdownLabel}
              </span>
            )}
          </div>
          <SubframeCore.Icon
            name="FeatherChevronDown"
            className={classNames(
              "h-4 w-4 text-muted-foreground transition-transform duration-200",
              isOpen && "transform rotate-180"
            )}
          />
        </div>

        {/* Dropdown Menu */}
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg animate-in fade-in-0 zoom-in-95 duration-100 ">
            {isSearchable && (
              <div className="p-1 border rounded rounded-xs border-gray-100">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-3 py-2 text-sm bg-muted/50 border-none rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            )}
            <div className="max-h-[185px] overflow-y-auto py-1">
              {isEmpty(filteredDropdownItems) ? (
                <div className="px-3 py-2 text-sm text-muted-foreground">
                  No results found
                </div>
              ) : (
                filteredDropdownItems.map((item) => {
                  const isSelected = selectedValues.find(
                    (v) => v.value === item.value
                  );
                  return (
                    <div
                      key={item.value}
                      onClick={() => toggleItem(item.value)}
                      className={classNames(
                        "flex items-center px-3 py-2 text-sm cursor-pointer transition-colors",
                        isSelected ? "bg-primary/10" : "hover:bg-muted/50"
                      )}
                    >
                      <div
                        className={classNames(
                          "flex items-center justify-center w-4 h-4 mr-2 rounded border transition-colors",
                          isSelected
                            ? "border-primary bg-primary text-primary-foreground"
                            : "border-muted-foreground/30"
                        )}
                      >
                        {isSelected && (
                          <SubframeCore.Icon
                            name="FeatherCheck"
                            className="h-3 w-3"
                          />
                        )}
                      </div>
                      <span className={classNames(isSelected && "font-medium")}>
                        {item.label}
                      </span>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
