import { AppBar, Box, Snackbar, Toolbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import { EMPLOYEE_ROLES } from "constants/constants";
import useUser from "hooks/userHooks";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { logoutEmployee } from "services/auth";
import {
  mixpanelInitialize,
  mixpanelLogoutEvent,
  mixpanelRegister,
} from "services/mixpanel";
import { Avatar, DropdownMenu, Loader } from "subframe";
import { SidebarLevo } from "subframe/components/SidebarLevo";
import { get, remove } from "utils/localStorage";
import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "utils/string";
import Logo from "../../../assets/images/logoV2.svg";
import SearchEmployeesDialog from "./SearchEmployeesDialog";
import { useTheme } from "@mui/material/styles";

export default function Retention() {
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  const searchEmployeesDialogRef = useRef(null);

  const { user, isLoading: isLoadingUser } = useUser();

  const theme = useTheme();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      mixpanelInitialize();
      mixpanelRegister(user);
    }
  }, [user]);

  const handleLogout = async () => {
    const userRole = get("userRole");
    const email = get("email");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      setIsLoggingOut(true);

      try {
        await logoutEmployee(email);
      } catch (error) {
        setErrorMessage(`Error while logging out: ${error?.message}`);
        setIsErrorMessageShown(true);

        return;
      } finally {
        setIsLoggingOut(false);
      }
    }

    remove("token");
    remove("refreshToken");
    remove("userRole");
    remove("email");
    remove("agency_name");
    remove("full_name");
    navigate("/");
    mixpanelLogoutEvent(); // Emit logout analytics event
  };

  const getLoggedInUserInfo = () => {
    const userRole = get("userRole");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      return (
        <div className="flex flex-col items-start gap-1">
          <span className="text-body-bold font-body-bold text-default-font">
            {getFullName(user?.first_name, user?.last_name)}
          </span>
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherUserCog"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(EMPLOYEE_ROLES[user?.["role-type"]]?.label)
                  ? "-"
                  : EMPLOYEE_ROLES[user?.["role-type"]]?.label}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherMail"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(user?.email) ? "-" : user.email}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <SubframeCore.Icon
                className="text-label font-label text-subtext-color"
                name="FeatherMapPin"
              />
              <span className="text-label font-label text-subtext-color">
                {isStringEmpty(user?.region) ? "-" : user.region}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <span className="text-default-font text-body-bold font-body-bold">
        {isStringEmpty(user?.name) ? "-" : user.name}
      </span>
    );
  };

  const getLoggedInUserAvatar = () => {
    const userRole = get("userRole");

    if (Object.keys(EMPLOYEE_ROLES).includes(userRole)) {
      return (
        <Avatar className="cursor-pointer">
          {getAcronym(getFullName(user?.first_name, user?.last_name))}
        </Avatar>
      );
    }

    return <Avatar className="cursor-pointer">{getAcronym(user?.name)}</Avatar>;
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <SidebarLevo logoImage={Logo}>
          <div className="grow shrink-0 basis-0 h-full w-full gap-3 items-start flex flex-row md:flex-col ">
            {user?.configuration?.enable_employee_search && (
              <SidebarLevo.SidebarItem
                icon="FeatherSearch"
                selected={
                  location.pathname === "/retention/V2/employees/search"
                }
                onClick={() => {
                  searchEmployeesDialogRef.current?.setOpen(true);
                }}
              >
                Search
              </SidebarLevo.SidebarItem>
            )}
            <SidebarLevo.SidebarItem
              icon="FeatherInbox"
              selected={location.pathname === "/retention/V2/inbox"}
              onClick={() => navigate("/retention/V2/inbox")}
            >
              Inbox
            </SidebarLevo.SidebarItem>
            <SidebarLevo.SidebarItem
              icon="FeatherBarChart2"
              selected={location.pathname.startsWith("/retention/V2/reports")}
              onClick={() => navigate("/retention/V2/reports")}
            >
              Reports
            </SidebarLevo.SidebarItem>
            <SidebarLevo.SidebarItem
              icon="FeatherUsers"
              selected={location.pathname.startsWith("/retention/V2/roster")}
              onClick={() => navigate("/retention/V2/roster")}
            >
              Employees
            </SidebarLevo.SidebarItem>
            <SidebarLevo.SidebarItem
              icon="FeatherSettings"
              selected={location.pathname === "/retention/V2/settings"}
              onClick={() => navigate("/retention/V2/settings")}
            >
              Settings
            </SidebarLevo.SidebarItem>
          </div>
          <div className="w-full flex-col gap-2 pt-6 pr-6 pb-6 pl-6 items-center justify-end hidden md:flex">
            {!isLoadingUser && (
              <SubframeCore.DropdownMenu.Root>
                <SubframeCore.DropdownMenu.Trigger asChild={true}>
                  {getLoggedInUserAvatar()}
                </SubframeCore.DropdownMenu.Trigger>
                <SubframeCore.DropdownMenu.Portal>
                  <SubframeCore.DropdownMenu.Content
                    side="right"
                    align="end"
                    sideOffset={8}
                    asChild={true}
                    style={{ zIndex: 99 }}
                  >
                    <DropdownMenu>
                      <div className="grow shrink-0 basis-0 h-full w-full flex-col items-start flex">
                        <div className="grow shrink-0 basis-0 h-full w-full flex-col pt-2 pr-3 pb-2 pl-3 items-start flex">
                          {getLoggedInUserInfo()}
                        </div>
                        <div className="w-full flex-col pt-0.5 pb-0.5 items-start flex">
                          <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
                        </div>
                        <div
                          className="w-full h-8 gap-1.5 pr-3 pl-3 justify-between items-center  flex cursor-pointer hover:bg-neutral-100 data-[highlighted]:bg-neutral-100"
                          onClick={handleLogout}
                        >
                          <div className="flex items-center gap-1.5">
                            <SubframeCore.Icon
                              className="text-neutral-700 text-body font-body"
                              name="FeatherLogOut"
                            />
                            <span className="text-neutral-700 text-body font-body">
                              Log Out
                            </span>
                          </div>
                          {isLoggingOut && <Loader />}
                        </div>
                      </div>
                    </DropdownMenu>
                  </SubframeCore.DropdownMenu.Content>
                </SubframeCore.DropdownMenu.Portal>
              </SubframeCore.DropdownMenu.Root>
            )}
          </div>
        </SidebarLevo>
        <div className="w-full h-full lg:max-w-[calc(100%-96px)]">
          <AppBar
            position="sticky"
            sx={{
              top: 0,
              zIndex: 99,
              height: "75px",
              backgroundColor: "#eff6ff",
              color: "#2563eb",
              boxShadow: "none",
              fontFamily: "Nunito",
            }}
            className="block md:!hidden sticky"
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box display="flex" alignItems="center">
                <img src={Logo} className="h-6 flex-none" alt="levo-logo" />
              </Box>

              {!isLoadingUser && (
                <SubframeCore.DropdownMenu.Root>
                  <SubframeCore.DropdownMenu.Trigger asChild={true}>
                    {getLoggedInUserAvatar()}
                  </SubframeCore.DropdownMenu.Trigger>
                  <SubframeCore.DropdownMenu.Portal>
                    <SubframeCore.DropdownMenu.Content
                      side="right"
                      align="end"
                      sideOffset={8}
                      asChild={true}
                      style={{ zIndex: 99, backgroundColor: "#ffffff" }}
                      className={"shadow-md"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "200px",
                          padding: theme.spacing(1),
                        }}
                      >
                        <Box
                          sx={{
                            padding: theme.spacing(2),
                            borderBottom: `1px solid ${theme.palette.divider}`,
                          }}
                        >
                          {getLoggedInUserInfo()}
                        </Box>
                        <Box
                          sx={{
                            padding: theme.spacing(1, 3),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                          onClick={handleLogout}
                        >
                          <SubframeCore.Icon
                            name="FeatherLogOut"
                            style={{ color: "#555" }}
                          />
                          <span className="text-neutral-700 text-body font-body">
                            Log Out
                          </span>
                          {isLoggingOut && <Loader />}
                        </Box>
                      </Box>
                    </SubframeCore.DropdownMenu.Content>
                  </SubframeCore.DropdownMenu.Portal>
                </SubframeCore.DropdownMenu.Root>
              )}
            </Toolbar>
          </AppBar>
          <div className="h-[calc(100vh-150px)] md:h-[100vh] overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </Box>
      {user?.configuration?.enable_employee_search && (
        <SearchEmployeesDialog ref={searchEmployeesDialogRef} />
      )}
      {renderErrorSnackbar()}
    </>
  );
}
