/*
 * Documentation:
 * Reports page — https://app.subframe.com/library?component=Reports+page_1b2073e5-8ee6-4c66-a27b-68b29a0a2dca
 * Select — https://app.subframe.com/library?component=Select_bb88f90b-8c43-4b73-9c2f-3558ce7838f3
 * Page Header — https://app.subframe.com/library?component=Page+Header_4563daa8-568e-4a98-924a-0d956da3e7ec
 * Custom Stat Card — https://app.subframe.com/library?component=Custom+Stat+Card_ec6b5f2a-cbac-4a61-abfc-669880be3c72
 * Icon with background — https://app.subframe.com/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 * Checkbox Card — https://app.subframe.com/library?component=Checkbox+Card_de0b4dfb-3946-4702-be52-5678dd71925a
 * Tooltip — https://app.subframe.com/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 * Area Chart — https://app.subframe.com/library?component=Area+Chart_8aa1e7b3-5db6-4a62-aa49-137ced21a231
 */

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import React, { useCallback, useEffect, useState } from "react";
import { PageHeader } from "subframe/components/PageHeader";

import { isEmpty as isArrayEmpty } from "utils/array";
import { isEmpty as isStringEmpty } from "utils/string";
import { get } from "../../../../utils/localStorage";

import RetentionDashboardV2 from "components/RetentionDashboardV2/RetentionDashboardV2";
import { REPORTS_DASHBOARD } from "constants/constants";
import { EVENT_TYPES } from "constants/mixpanel";
import { useNavigate } from "react-router-dom";
import { mixpanelTrackEvent } from "services/mixpanel";
import config from "../../../../config";
import EmbedDashboard from "../Dashboards/EmbedDashboard";
import JobRatiosSettings from "../JobRatiosSettings";
import LaborReportData from "../LaborReportData";

function Reports() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [lastRatiosUpdate, setLastRatiosUpdate] = useState(Date.now());

  const handleRatiosUpdated = useCallback(() => {
    setLastRatiosUpdate(Date.now());
  }, []);

  useEffect(() => {
    mixpanelTrackEvent(EVENT_TYPES.REPORTS_PAGE_NAVIGATION);
  }, []);

  const agencyName = get("agency_name");
  const agencyConfig = REPORTS_DASHBOARD[agencyName] || {};
  const dashboardConfig = {
    showOnlyEmbedReports: agencyConfig.showOnlyEmbedReports || false,
    showAgencySpecificEmbeddedReports:
      agencyConfig.showAgencySpecificEmbeddedReports || false,
    showLevoEmbeddedReports: agencyConfig.showLevoEmbeddedReports ?? true,
    showOtherReports: agencyConfig.showOtherReports ?? true,
    embedReportIds: agencyConfig.embedIds,
  };

  const renderEmbedDashboards = (embedIdStr) => {
    if (isStringEmpty(embedIdStr)) return null;

    const embedIds = embedIdStr.split(",");
    if (isArrayEmpty(embedIds)) return null;

    return embedIds.map((id) => (
      <EmbedDashboard key={`embed-dashboard-${id}`} id={id} />
    ));
  };

  const mainContent = dashboardConfig.showOnlyEmbedReports ? (
    renderEmbedDashboards(dashboardConfig.embedReportIds)
  ) : (
    <>
      {dashboardConfig.showOtherReports && (
        <>
          {/*<JobRatiosSettings onRatiosUpdated={handleRatiosUpdated} />*/}
          {/*<LaborReportData key={lastRatiosUpdate} />*/}
        </>
      )}
      {dashboardConfig.showLevoEmbeddedReports &&
        renderEmbedDashboards(config.embedDashboardIds)}
      {dashboardConfig.showAgencySpecificEmbeddedReports &&
        renderEmbedDashboards(dashboardConfig.embedReportIds)}
      {dashboardConfig.showOtherReports && <RetentionDashboardV2 />}
    </>
  );

  return (
    <>
      <div className="w-full h-full flex-col gap-8 p-4 md:p-12 items-start flex bg-default-background max-w-none overflow-auto">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-neutral-400 text-header font-header"
              name="FeatherBarChart2"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
              Reports
            </span>
          </div>
        </PageHeader>
        {mainContent}
      </div>
      <Snackbar
        open={isErrorMessageShown}
        autoHideDuration={6000}
        onClose={() => setIsErrorMessageShown(false)}
      >
        <MuiAlert
          onClose={() => setIsErrorMessageShown(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default React.memo(Reports);
