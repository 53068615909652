/*
 * Documentation:
 * Roster — https://app.subframe.com/library?component=Roster_d597eb02-798c-40ea-944b-7a701165fdf7
 * Checkbox — https://app.subframe.com/library?component=Checkbox_3816e3b5-c48c-499b-b45e-0777c6972523
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Text Input — https://app.subframe.com/library?component=Text+ Input_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Stats card row — https://app.subframe.com/library?component=Stats+card+row_5f847462-1a2a-42e8-a92a-bef25d2ad7e7
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 */

import MuiAlert from "@mui/material/Alert";
import { useUpdateEffect } from "react-use";
import { useNavigate } from "react-router-dom";
import { Skeleton, Snackbar } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import { debounce } from "lodash";

import { CustomStatCard, PageHeader } from "subframe";
import * as SubframeCore from "@subframe/core";
import { Select } from "subframe/components/Select";

import { fetchRoster } from "services/retention/employees";
import { fetchOfficeLocations } from "services/retention/employees";

import { ERROR_CODE } from "constants/constants";

import {
  fetchJobs,
  fetchRosterStats,
  fetchTenures,
  fetchCurrentStats,
} from "modules/Retention/services/retention";
import { ROSTER_DATE_FILTER_OPTIONS } from "modules/Retention/constants/constants";

import { isNumber } from "utils/number";
import { getLastNDaysFromToday } from "utils/date";
import { isEmpty as isArrayEmpty } from "utils/array";

import { mixpanelTrackEvent } from "services/mixpanel";
import { EVENT_TYPES } from "constants/mixpanel";

import RosterGrid from "../RosterGrid";

// Field mapping constants
const FIELD_MAP = {
  employee: "name",
  "profile.job_title": "job_title",
  "profile.office_location": "office_location",
  "profile.tenure": "tenure",
  "survey.['Sentiment Score']": "sentiment_score",
  "latest_survey.['trigger_day']": "latest_trigger_day",
  "latest_survey.['Sentiment Score']": "latest_sentiment",
  "latest_survey.['created_at']": "latest_created_at",
};

const FILTER_FIELD_MAP = {
  employee: "employee_name",
  "profile.job_title": "job_title",
  "profile.office_location": "location",
  "profile.tenure": "tenure",
};

// Utility functions
const getSortField = (field) => FIELD_MAP[field] || field;
const getFilterField = (field) => {
  const cleanField = field.replace("profile.", "");
  return FILTER_FIELD_MAP[cleanField] || FILTER_FIELD_MAP[field] || field;
};

function Roster() {
  const defaultRosterDateFilter = ROSTER_DATE_FILTER_OPTIONS["Last 7 days"];
  const [roster, setRoster] = useState([]);
  const [isLoadingRoster, setIsLoadingRoster] = useState(false);
  const [isLoadingRosterStats, setIsLoadingRosterStats] = useState(false);
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [jobsData, setJobsData] = useState([]);
  const [tenures, setTenures] = useState([]);
  const [currentStats, setCurrentStats] = useState({});
  const [isLoadingCurrentStats, setIsLoadingCurrentStats] = useState(false);

  const [filter, setFilter] = useState({
    endDate:
      getLastNDaysFromToday(defaultRosterDateFilter.value)?.endDate || null,
    startDate:
      getLastNDaysFromToday(defaultRosterDateFilter.value)?.startDate || null,
  });
  const [selectedRosterDateFilter, setSelectedRosterDateFilter] = useState(
    defaultRosterDateFilter.label
  );
  const [rosterStats, setRosterStats] = useState({});
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: "and",
  });
  const [escalationFilters, setEscalationFilters] = useState({
    has_at_risk_escalation: null,
    has_in_progress_escalation: null,
  });

  /**
   * Roster table pagination states.
   */
  const [rosterPaginationModel, setRosterPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rosterRowCountState, setRosterRowCountState] = useState(0);

  // API calls
  const fetchRosterAsync = async (params = {}) => {
    try {
      const {
        sort_by,
        sort_order,
        page,
        pageSize,
        start_date,
        end_date,
        ...filterParams
      } = params;

      const requestParams = {
        page: page + 1,
        limit: pageSize,
        sort_by: sort_by ? getSortField(sort_by) : undefined,
        sort_order,
        start_date,
        end_date,
        ...filterParams,
      };

      const response = await fetchRoster(requestParams);
      return response;
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching Roster: ${error.message}`);
      setIsErrorMessageShown(true);
      return { hasError: true, error };
    }
  };

  const fetchRosterStatsAsync = async (filter = {}) => {
    try {
      const response = await fetchRosterStats({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
      return response;
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      setErrorMessage(`Error while fetching Roster stats: ${error.message}`);
      setIsErrorMessageShown(true);
      return { hasError: true, error };
    }
  };

  // Data fetching handlers
  const handleFetchRoster = async (params = {}) => {
    setIsLoadingRoster(true);
    const requestParams = {
      ...params,
      start_date: filter?.startDate,
      end_date: filter?.endDate,
    };

    const response = await fetchRosterAsync(requestParams);
    setIsLoadingRoster(false);

    if (response?.hasError) {
      setRoster([]);
      return;
    }

    setRoster(response?.result?.employees);
    setRosterRowCountState(response?.result?.total || 0);
  };

  const handleFetchRosterStats = async (filter = {}) => {
    setIsLoadingRosterStats(true);
    const response = await fetchRosterStatsAsync(filter);
    setIsLoadingRosterStats(false);

    if (response?.hasError) {
      setRosterStats({});
      return;
    }

    setRosterStats(response?.result?.data);
  };

  const handleFetchJobs = async () => {
    try {
      const response = await fetchJobs();
      if (response?.hasError) {
        setErrorMessage(`Error fetching jobs: ${response?.error?.message}`);
        setIsErrorMessageShown(true);
        return;
      }
      if (response?.success) {
        setJobsData(response.result.jobs);
      }
    } catch (error) {
      setErrorMessage(`Error fetching jobs: ${error.message}`);
      setIsErrorMessageShown(true);
    }
  };

  const handleFetchLocations = async () => {
    try {
      const response = await fetchOfficeLocations();
      if (response?.hasError) {
        setErrorMessage(
          `Error fetching locations: ${response?.error?.message}`
        );
        setIsErrorMessageShown(true);
        return;
      }
      if (response?.success) {
        setLocations(response.result.office_locations || []);
      }
    } catch (error) {
      setErrorMessage(`Error fetching locations: ${error.message}`);
      setIsErrorMessageShown(true);
    }
  };

  const handleFetchTenures = async () => {
    try {
      const response = await fetchTenures();
      if (response?.hasError) {
        setErrorMessage(`Error fetching tenures: ${response?.error?.message}`);
        setIsErrorMessageShown(true);
        return;
      }
      if (response?.success) {
        setTenures(response.result.tenures.map((t) => t.tenure));
      }
    } catch (error) {
      setErrorMessage(`Error fetching tenures: ${error.message}`);
      setIsErrorMessageShown(true);
    }
  };

  const handleFetchCurrentStats = async () => {
    setIsLoadingCurrentStats(true);
    try {
      const response = await fetchCurrentStats({
        ...buildFilterParams(filterModel?.items || []),
      });
      if (response?.hasError) {
        setErrorMessage(
          `Error fetching current stats: ${response?.error?.message}`
        );
        setIsErrorMessageShown(true);
        return;
      }
      if (response?.success) {
        setCurrentStats(response.result.data);
      }
    } catch (error) {
      setErrorMessage(`Error fetching current stats: ${error.message}`);
      setIsErrorMessageShown(true);
    }
    setIsLoadingCurrentStats(false);
  };

  // Filter handling
  const buildFilterParams = (items = []) => {
    const filterParams = {};

    // Handle escalation filters
    if (escalationFilters.has_at_risk_escalation) {
      filterParams.has_at_risk_escalation = true;
    }
    if (escalationFilters.has_in_progress_escalation) {
      filterParams.has_in_progress_escalation = true;
    }

    // Handle other filters
    items?.forEach((item) => {
      if (
        !item.columnField ||
        (!item.value && !["isEmpty", "isNotEmpty"].includes(item.operatorValue))
      ) {
        return;
      }

      const fieldName = getFilterField(item.columnField);
      if (!fieldName) return;

      if (fieldName === "employee_name") {
        filterParams[fieldName] =
          item.operatorValue === "isEmpty" ||
          item.operatorValue === "isNotEmpty"
            ? "_empty_check_"
            : item.value;
        filterParams.name_filter_type = item.operatorValue || "contains";
        return;
      }

      if (fieldName === "job_title" && !isArrayEmpty(jobsData)) {
        const jobId = handleJobFilter(item);
        if (jobId) filterParams.job_id = jobId;
        return;
      }

      if (fieldName === "location" && item.operatorValue === "isAnyOf") {
        if (Array.isArray(item.value) && !isArrayEmpty(item.value)) {
          filterParams.location = item.value.join(",");
          return;
        }
      }

      if (fieldName === "tenure") {
        const tenureValue = handleTenureFilter(item);
        if (tenureValue) filterParams.tenure = tenureValue;
        return;
      }

      filterParams[fieldName] =
        item.operatorValue === "not" ? `!${item.value}` : item.value;

      if (["isEmpty", "isNotEmpty"].includes(item.operatorValue)) {
        filterParams[`${fieldName}_operator`] = item.operatorValue;
      }
    });

    return filterParams;
  };

  const handleJobFilter = (item) => {
    if (item.operatorValue === "isAnyOf" && Array.isArray(item.value)) {
      const selectedJobIds = item.value
        .map(
          (jobTitle) => jobsData.find((job) => job.job_title === jobTitle)?.id
        )
        .filter((id) => id);
      return !isArrayEmpty(selectedJobIds) ? selectedJobIds.join(",") : null;
    }

    const selectedJob = jobsData.find((job) => job.job_title === item.value);
    return selectedJob?.id
      ? item.operatorValue === "not"
        ? `!${selectedJob.id}`
        : selectedJob.id
      : null;
  };

  const handleTenureFilter = (item) => {
    if (item.operatorValue === "isAnyOf" && Array.isArray(item.value)) {
      return !isArrayEmpty(item.value) ? item.value.join(",") : null;
    }
    return item.operatorValue === "not" ? `!${item.value}` : item.value;
  };

  // Event handlers
  const handleFilterChange = async (newModel) => {
    if (!newModel) {
      return;
    }

    setFilterModel(newModel);
    const changedFilter = findChangedFilter(newModel);
    if (!shouldMakeFilterCall(changedFilter)) return;

    const baseParams = {
      ...rosterPaginationModel,
      sort_by: sortModel[0]?.field,
      sort_order: sortModel[0]?.sort?.toLowerCase(),
      start_date: filter?.startDate,
      end_date: filter?.endDate,
    };

    if (changedFilter && changedFilter.columnField === "employee") {
      handleEmployeeFilter(changedFilter, baseParams);
    } else {
      handleOtherFilters(newModel, baseParams);
    }

    // Set loading state before fetching current stats
    setIsLoadingCurrentStats(true);
    try {
      const response = await fetchCurrentStats(
        buildFilterParams(newModel.items || [])
      );
      if (response?.success) {
        setCurrentStats(response.result.data);
      }
    } catch (error) {
      setErrorMessage(`Error fetching current stats: ${error.message}`);
      setIsErrorMessageShown(true);
    } finally {
      setIsLoadingCurrentStats(false);
    }
  };

  const findChangedFilter = (newModel) => {
    if (!newModel || !newModel.items) {
      return undefined;
    }

    return newModel.items.find((item) => {
      const previousFilter = filterModel?.items?.find(
        (f) => f.columnField === item.columnField
      );
      return (
        !previousFilter ||
        previousFilter.value !== item.value ||
        previousFilter.operatorValue !== item.operatorValue
      );
    });
  };

  const shouldMakeFilterCall = (changedFilter) => {
    if (!changedFilter || !changedFilter.columnField) return false;

    const wasValueCleared =
      filterModel?.items?.find(
        (f) => f.columnField === changedFilter.columnField
      )?.value && changedFilter.value === undefined;

    const isEmptyOperator = ["isEmpty", "isNotEmpty"].includes(
      changedFilter.operatorValue
    );

    return changedFilter.value || wasValueCleared || isEmptyOperator;
  };

  const handleEmployeeFilter = (changedFilter, baseParams) => {
    if (!changedFilter || !changedFilter.columnField) {
      return;
    }

    const fieldName = getFilterField("employee");
    const employeeFilter = {
      ...baseParams,
      [fieldName]:
        changedFilter.operatorValue === "isEmpty" ||
        changedFilter.operatorValue === "isNotEmpty"
          ? "_empty_check_"
          : changedFilter.value,
      name_filter_type: changedFilter.operatorValue || "contains",
    };
    setIsLoadingRoster(true);
    debouncedTextFilterFetch(employeeFilter);
  };

  const handleOtherFilters = (newModel, baseParams) => {
    if (!newModel || !newModel.items) {
      return;
    }

    setIsLoadingRoster(true);
    handleFetchRoster({
      ...baseParams,
      ...buildFilterParams(newModel.items),
    });
  };

  const handleSortModelChange = async (newModel) => {
    if (!newModel) {
      return;
    }

    setSortModel(newModel);
    setIsLoadingRoster(true);

    const sortField = newModel[0]?.field;

    try {
      const response = await fetchRosterAsync({
        ...rosterPaginationModel,
        ...buildFilterParams(filterModel?.items || []),
        sort_by: sortField,
        sort_order: newModel[0]?.sort?.toLowerCase(),
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      });
      handleFetchResponse(response);
    } catch (error) {
      setErrorMessage(`Error while sorting: ${error.message}`);
      setIsErrorMessageShown(true);
      setIsLoadingRoster(false);
    }
  };

  const handleFetchResponse = (response) => {
    setIsLoadingRoster(false);
    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Roster: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setRoster([]);
      return;
    }
    setRoster(response?.result?.employees);
    setRosterRowCountState(response?.result?.total || 0);
  };

  // Debounced handlers
  const debouncedTextFilterFetch = useCallback(
    debounce(handleFetchRoster, 2000),
    []
  );

  const debouncedFetchRoster = useCallback(
    debounce(handleFetchRoster, 500),
    []
  );

  // Effects
  useEffect(() => {
    handleFetchRoster(rosterPaginationModel);
    handleFetchLocations();
    handleFetchTenures();
    handleFetchCurrentStats();
    handleFetchJobs();
    mixpanelTrackEvent(EVENT_TYPES.EMPLOYEES_PAGE_NAVIGATION);
  }, []);

  useEffect(() => {
    handleFetchRosterStats(filter);
  }, [filter]);

  useUpdateEffect(() => {
    const sortParams =
      !isArrayEmpty(sortModel) && sortModel[0]?.sort
        ? {
            sort_by: sortModel[0]?.field,
            sort_order: sortModel[0]?.sort?.toLowerCase(),
          }
        : {};

    debouncedFetchRoster({
      ...rosterPaginationModel,
      ...buildFilterParams(filterModel?.items || []),
      ...sortParams,
      start_date: filter?.startDate,
      end_date: filter?.endDate,
    });
  }, [rosterPaginationModel]);

  const handleGetLastNDaysFromToday = (n = 0) => {
    const result = getLastNDaysFromToday(n);
    const startDate =
      dayjs(result?.startDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.startDate)?.format("YYYY-MM-DD");
    const endDate =
      dayjs(result?.endDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.endDate)?.format("YYYY-MM-DD");

    setFilter({
      startDate,
      endDate,
    });
  };

  /**
   * Navigate to the selected employee's profile.
   */
  const navigateToEmployeeProfile = (employeeId) => {
    if (!isNaN(employeeId)) {
      window.open(`/retention/V2/employees/${employeeId}`, "_blank");
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderSuccessSnackbar = () => (
    <Snackbar
      open={!!successMessage}
      autoHideDuration={6000}
      onClose={() => setSuccessMessage("")}
    >
      <MuiAlert
        onClose={() => setSuccessMessage("")}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  const handleStatCardClick = (type) => {
    let newFilters = { ...escalationFilters };

    if (type === "at-risk") {
      // If at-risk is already true, set it to null
      if (newFilters.has_at_risk_escalation === true) {
        newFilters = {
          has_at_risk_escalation: null,
          has_in_progress_escalation: null,
        };
      } else {
        // Otherwise select it and unselect in-progress
        newFilters = {
          has_at_risk_escalation: true,
          has_in_progress_escalation: null,
        };
      }
    } else if (type === "in-progress") {
      // If in-progress is already true, set it to null
      if (newFilters.has_in_progress_escalation === true) {
        newFilters = {
          has_at_risk_escalation: null,
          has_in_progress_escalation: null,
        };
      } else {
        // Otherwise select it and unselect at-risk
        newFilters = {
          has_at_risk_escalation: null,
          has_in_progress_escalation: true,
        };
      }
    }

    // Only update the state - fetch will be handled by useEffect
    setEscalationFilters(newFilters);
  };

  // Add new useEffect to handle fetching when escalation filters change
  useEffect(() => {
    const sortParams =
      !isArrayEmpty(sortModel) && sortModel[0]?.sort
        ? {
            sort_by: sortModel[0]?.field,
            sort_order: sortModel[0]?.sort?.toLowerCase(),
          }
        : {};

    const escalationFilterParams = {
      ...rosterPaginationModel,
      ...buildFilterParams(filterModel?.items || []),
      ...sortParams,
      start_date: filter?.startDate,
      end_date: filter?.endDate,
    };

    handleFetchRoster(escalationFilterParams);
  }, [escalationFilters]);

  return (
    <>
      <div className="w-full h-full flex-col gap-8 p-4 md:p-12 items-start flex bg-default-background max-w-none overflow-auto">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-neutral-400 text-header font-header"
              name="FeatherUsers"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
              Employees
            </span>
          </div>
          <Select
            className="flex-none w-auto h-9"
            placeholder="Last 7 days"
            size="Small"
            value={selectedRosterDateFilter}
            onValueChange={(label) => {
              setSelectedRosterDateFilter(label);
              handleGetLastNDaysFromToday(
                ROSTER_DATE_FILTER_OPTIONS[label].value
              );
            }}
          >
            {Object.keys(ROSTER_DATE_FILTER_OPTIONS).map((key) => (
              <Select.Item
                value={ROSTER_DATE_FILTER_OPTIONS[key].label}
                key={ROSTER_DATE_FILTER_OPTIONS[key].value}
              />
            ))}
          </Select>
        </PageHeader>

        <div className="w-full flex-col gap-4 items-start flex">
          <PageHeader>
            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
              <SubframeCore.Icon
                className="text-subtext-color text-section-header font-section-header"
                name="FeatherGauge"
              />
              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                Company Highlights
              </span>
            </div>
          </PageHeader>
          <div className="w-full flex flex-col gap-4">
            {/* Top row - Roster Stats */}
            <div className="w-full gap-4 items-center flex">
              {isLoadingRosterStats ? (
                <>
                  <Skeleton variant="rounded" width="100%" height="85px" />
                  <Skeleton variant="rounded" width="100%" height="85px" />
                  <Skeleton variant="rounded" width="100%" height="85px" />
                </>
              ) : (
                <>
                  <div className="w-full">
                    <CustomStatCard
                      title="Responses"
                      value={
                        isNumber(rosterStats?.survey_completed)
                          ? rosterStats?.survey_completed?.toString()
                          : "-"
                      }
                      variant="brand"
                    />
                  </div>
                  <div className="w-full">
                    <CustomStatCard
                      title="Response Rate"
                      value={
                        isNumber(rosterStats?.response_rate)
                          ? `${rosterStats?.response_rate?.toString()}%`
                          : "-"
                      }
                      variant="brand"
                    />
                  </div>
                  <div className="w-full">
                    <CustomStatCard
                      title="Avg. Sentiment Score"
                      value={
                        isNumber(rosterStats?.overall_sentiment_score)
                          ? rosterStats?.overall_sentiment_score?.toString()
                          : "-"
                      }
                      variant="brand"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className="w-full border-t border-neutral-200"></div>
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-subtext-color text-section-header font-section-header"
              name="FeatherGauge"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
              Roster Highlights
            </span>
          </div>
        </PageHeader>
        {/* Bottom row - Current Stats */}
        <div className="w-full gap-4 items-center flex">
          {isLoadingCurrentStats ? (
            <>
              <Skeleton variant="rounded" width="100%" height="85px" />
              <Skeleton variant="rounded" width="100%" height="85px" />
              <Skeleton variant="rounded" width="100%" height="85px" />
            </>
          ) : (
            <>
              <div
                onClick={() => handleStatCardClick("at-risk")}
                className={`w-full cursor-pointer ${
                  escalationFilters.has_at_risk_escalation
                    ? "ring-2 ring-error-600 rounded"
                    : ""
                }`}
              >
                <CustomStatCard
                  title="At Risk"
                  value={
                    isNumber(currentStats?.at_risk_employees_count)
                      ? currentStats?.at_risk_employees_count?.toString()
                      : "-"
                  }
                  variant="at-risk"
                />
              </div>
              <div
                onClick={() => handleStatCardClick("in-progress")}
                className={`w-full cursor-pointer ${
                  escalationFilters.has_in_progress_escalation
                    ? "ring-2 ring-warning-600 rounded"
                    : ""
                }`}
              >
                <CustomStatCard
                  title="In Progress"
                  value={
                    isNumber(currentStats?.in_progress_employees_count)
                      ? currentStats?.in_progress_employees_count?.toString()
                      : "-"
                  }
                  variant="escalated"
                />
              </div>
              <div className="w-full">
                <CustomStatCard
                  title="Current Sentiment"
                  value={
                    isNumber(currentStats?.current_sentiment_score)
                      ? currentStats?.current_sentiment_score?.toString()
                      : "-"
                  }
                  variant="resolved"
                />
              </div>
            </>
          )}
        </div>
        <div className="w-full min-h-[650px] h-full flex-col gap-4 items-start flex">
          <PageHeader>
            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
              <SubframeCore.Icon
                className="text-subtext-color text-section-header font-section-header"
                name="FeatherUsers2"
              />
              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                Roster
              </span>
            </div>
          </PageHeader>
          <div className="w-full h-full">
            <RosterGrid
              rowCount={rosterRowCountState}
              rows={roster}
              paginationModel={rosterPaginationModel}
              isLoading={isLoadingRoster}
              setRosterPaginationModel={setRosterPaginationModel}
              viewEmployeeProfile={navigateToEmployeeProfile}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              filterModel={filterModel}
              onFilterModelChange={handleFilterChange}
              availableLocations={locations}
              jobsData={jobsData}
              tenures={tenures}
            />
          </div>
        </div>
      </div>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}

export default Roster;
