import React from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";
import * as SubframeCore from "@subframe/core";

import { ACCESSIBILITY_TYPE } from "modules/Retention/constants/constants";
import { isEmpty } from "utils/array";

export default function UserPolicyMultiSelectDropdown({
  userPolicyOptions,
  initialData,
  accessibilityType,
  selectedValues,
  setSelectedValues,
}) {
  const [initialDropdownValues, setInitialDropdownValues] = React.useState([]);

  React.useEffect(() => {
    if (initialData) {
      function getInitialValue() {
        const currentType = initialData?.type;
        const currentItems = userPolicyOptions[currentType] || [];

        const transformValues = (values) =>
          values.split(",").map((value) => {
            const item = currentItems.find((item) => item.label === value);
            return item ? item : { label: value, value };
          });

        return initialData?.accessScope !== "-"
          ? transformValues(initialData.accessScope)
          : [];
      }

      const initialValues = getInitialValue();
      setSelectedValues(initialValues);
      setInitialDropdownValues(initialValues);
    }
  }, [JSON.stringify(initialData)]);

  const removeItem = (item, e) => {
    e?.stopPropagation();
    setSelectedValues((current) =>
      current.filter((cur) => cur.value !== item.value)
    );
  };

  const dropdownLabel = ACCESSIBILITY_TYPE.find(
    (item) => item.value === accessibilityType
  ).label;

  const dropdownItems = userPolicyOptions[accessibilityType];

  return (
    <>
      <MultiSelectDropdown
        initialValues={initialDropdownValues}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        dropdownLabel={dropdownLabel}
        dropdownItems={dropdownItems}
      />

      <h3 className="text-sm font-medium mt-4">Selected {dropdownLabel}</h3>
      <div className="border rounded-lg p-4 bg-background min-h-[200px]">
        {isEmpty(selectedValues) ? (
          <p className="text-sm text-muted-foreground">
            No accessibility policies selected
          </p>
        ) : (
          <div className="flex flex-wrap gap-2">
            {selectedValues.map((v) => {
              const selectedItem = dropdownItems.find(
                (f) => f.value === v.value
              );
              return (
                <div
                  key={v.value}
                  className="flex items-center gap-1 px-2.5 py-1 bg-gray-100 text-sm rounded-full border border-gray-400 group"
                >
                  <span>{selectedItem?.label}</span>
                  <button
                    onClick={(e) => removeItem(v, e)}
                    className="flex items-center justify-center h-4 w-4 rounded-full hover:bg-gray-400 hover:text-white transition-colors"
                  >
                    <SubframeCore.Icon name="FeatherX" className="h-3 w-3" />
                    <span className="sr-only">
                      Remove {selectedItem?.label}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
