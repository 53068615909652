import * as SubframeCore from "@subframe/core";
import OrganizationUserGridView from "../OrganizationUserGridView/OrganizationUserGridView";
import { useState } from "react";
import UserPolicyModal from "../UserPolicyModal/UserPolicyModal";
import { Badge } from "subframe/components/Badge";
import { ROLE_TYPE_ENUM } from "modules/Retention/constants/constants";

export default function OrganizationUserGrid({
  isLoading = false,
  data = [],
  rowCount = 0,
  paginationModel,
  onFilterModelChange = () => {},
  filterModel,
  handleSortModelChange = () => {},
  sortModel,
  updateData = () => {},
  userPolicyOptions,
  setPaginationModel = () => {},
}) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 90, filterable: false },
    {
      field: "employeeName",
      headerName: "Employee Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className={`custom-datagrid-cell-wrapper employee-cell`}>
              {params?.value}
            </div>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      align: "left",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      type: "string",
      renderCell: (params) => (
        <>
          <div
            className={`custom-datagrid-cell-wrapper 
            }`}
          >
            {params?.value}
          </div>
        </>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      align: "left",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const type =
          params?.value.toLowerCase() === "office_location"
            ? "Office Location"
            : params?.value.toLowerCase() === "region"
            ? "Region"
            : "-";

        return (
          <>
            <div className={`custom-datagrid-cell-wrapper `}>{type}</div>
          </>
        );
      },
    },
    {
      field: "accessScope",
      headerName: "Access Scope",
      align: "left",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const accessScopeList = (params?.value || "").split(",");
        const accessScopeValue = accessScopeList.join(", ");

        return (
          <>
            <div className={`custom-datagrid-cell-wrapper `}>
              {accessScopeValue}
            </div>
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      align: "left",
      headerClassName: "super-app-theme--header",
      width: 120,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className={`custom-datagrid-cell-wrapper capitalize`}>
              <Badge className="p-3 inline-flex" variant="neutral">
                {params?.value === ROLE_TYPE_ENUM.CAREGIVER ? "User": params?.value}
              </Badge>
            </div>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "left",
      sortable: false,
      filterable: false,
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: (params) => (
        <>
          <div className={`custom-datagrid-cell-wrapper`} key={params.row.id}>
            <button
              className="text-gray-600 hover:text-gray-800 p-1"
              onClick={() => {
                setActiveRow(params.row);
                setOpenEditModal(true);
              }}
            >
              <SubframeCore.Icon name="FeatherEdit3" className="text-left" />
            </button>
          </div>
        </>
      ),
    },
  ];

  const rows = (data || []).map((user) => ({
    id: user?.caregiver_id,
    employeeName: `${user?.first_name} ${user?.last_name}`,
    email: user?.email,
    role: user?.role_type,
    type: user.policy ? user.policy[0].type : "-",
    accessScope: user.policy ? user.policy[0].value.toString() : "-",
  }));

  return (
    <>
      <OrganizationUserGridView
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        isLoading={isLoading}
        sortModel={sortModel}
        onFilterModelChange={onFilterModelChange}
        filterModel={filterModel}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        setUserPaginationModel={setPaginationModel}
      />

      {openEditModal && (
        <UserPolicyModal
          onClose={() => setOpenEditModal(false)}
          open={openEditModal}
          data={activeRow}
          updateData={updateData}
          userPolicyOptions={userPolicyOptions}
        />
      )}
    </>
  );
}
