import { Outlet } from "react-router-dom";
import SignOutListener from "../SignOutListener/SignOutListener";

const RootLayout = () => {
    return (
        <>
            <SignOutListener />
            <Outlet />
        </>
    );
};

export default RootLayout;