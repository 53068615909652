import { DataGrid } from "@mui/x-data-grid";

export default function OrganizationUserGridView({
  rowCount,
  rows = [],
  columns = [],
  paginationModel,
  isLoading = false,
  setUserPaginationModel,
  sortModel,
  onSortModelChange,
  filterModel,
  onFilterModelChange,
}) {
  return (
    <div style={{ height: "600px", width: "100%" }}>
      <DataGrid
        rows={rows}
        rowCount={rowCount}
        loading={isLoading}
        pagination
        page={paginationModel?.page}
        pageSize={paginationModel?.pageSize}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        onPageChange={(newPage) =>
          setUserPaginationModel((old) => ({ ...old, page: newPage }))
        }
        onPageSizeChange={(newPageSize) =>
          setUserPaginationModel((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          fontFamily: "Nunito",
          color: "#171717",
        }}
      />
    </div>
  );
}
